
import ComponentsTest from 'dash-components-test/src/lib-components'
// import ComponentsMaster from 'dash-components-master'
import ComponentsLocal from '@components-lib'

const { VUE_APP_STAGE } = process.env

export default (...components) => {
  const library = VUE_APP_STAGE === 'local' ? ComponentsLocal : ComponentsTest

  return components
    .filter(component => library[component])
    .reduce((obj, component) => {
      return {
        ...obj,
        [component]: library[component]
      }
    }, {})
}
