
import global from '@/modules/global'
import ide from '@/modules/ide'
import apps from '@/modules/apps'
import cms from '@/modules/cms'
import settings from '@/modules/settings'
import auth from '@/modules/auth'
import checklist from '@/modules/checklist'
import settingsPwa from '@/modules/settings-pwa'
import pushNotification from '@/modules/push-notification'
import dataBase from '@/modules/data-base'

/**
 * Exportação da lista de módulos que devem ser usados na aplicação
 */

export default [
  ide,
  cms,
  apps,
  auth,
  global,
  settings,
  checklist,
  settingsPwa,
  pushNotification,
  dataBase
]
