
import rootfy from '@/core/utils/rootfy'
import toFormData from '@/core/utils/toformdata'

export default {
  state: {
    file: null,
    files: [],
    isFilesLoading: true
  },

  getters: {
    getFile: state => state.file,
    getFiles: state => rootfy(state.files),
    getIsFilesLoading: state => state.isFilesLoading,
    getPlainFiles: state => state.files.reduce((acc, cur) => acc.concat(cur.files), [])
  },

  mutations: {
    SET_FILE: (state, value) => (state.file = value),
    SET_FILES: (state, value) => (state.files = value),
    SET_IS_FILES_LOADING: (state, value) => (state.isFilesLoading = value)
  },

  actions: {
    /**
     * Create file
     */
    async createFile (
      { commit, dispatch },
      { data, done, fail }
    ) {
      try {
        if (!data.folder) {
          delete data.folder
        }

        data = toFormData(data)

        const id = window.$route.params.appID
        const response = await window
          .$request()
          .post(`/apps/${id}/file/create`, data)

        done(response.data)
        commit('SET_FILES', response.data)
      } catch (error) {
        console.log('[createFile]', error)
        fail(error.response ? error.response.data : error.message)
      }
    },

    async searchFile ({ commit }, fileID) {
      try {
        const appID = window.$route.params.appID

        const response = await window
          .$request()
          .get(`apps/${appID}/file/${fileID}`)

        commit('SET_FILE', response.data)
      } catch (error) {
        console.log('[searchFile]', error)
      }
    },

    async updateFile ({ commit }, { data, done, fail }) {
      try {
        const appID = window.$route.params.appID

        const { fileID, name, file } = data

        const formData = toFormData({ name, file })

        const response = await window
          .$request()
          .post(`/apps/${appID}/file/${fileID}/update`, formData)

        done(response.data)
        commit('SET_FILES', response.data)

        window.$toast({
          color: 'success',
          message: 'Arquivo atualizado com sucesso'
        })
      } catch (error) {
        fail(Object.assign(error, {
          message: 'Erro ao atualizar arquivo'
        }))

        console.trace('[updateFile]', error)
      }
    },

    async deleteFile ({ commit }, fileID) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request()
          .delete(`apps/${id}/file/${fileID}/remove`)

        commit('SET_FILES', response.data)

        window.$toast({
          color: 'success',
          message: 'Arquivo removido com sucesso'
        })
      } catch (error) {
        console.log('[deleteFile]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao excluir arquivo'
        })
      }
    },

    async searchFiles ({ commit }) {
      commit('SET_IS_FILES_LOADING', true)

      try {
        const id = window.$route.params.appID

        const response = await window
          .$request()
          .get(`/apps/${id}/folders`)

        commit('SET_FILES', response.data)
      } catch (error) {
        console.error('[searchFiles]', error)

        window.$toast({
          color: 'error',
          message: 'Houve um erro na busca dos arquivos'
        })
      } finally {
        commit('SET_IS_FILES_LOADING', false)
      }
    },

    async deleteFolder ({ commit }, { id, type }) {
      try {
        const appID = window.$route.params.appID

        const response = await window
          .$request()
          .delete(`/apps/${appID}/folder/${id}/remove?${type}`)

        commit('SET_FILES', response.data)

        window.$toast({
          color: 'success',
          message: 'Pasta removida com sucesso'
        })
      } catch (error) {
        console.log('[deleteFolder]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao excluir a pasta'
        })
      }
    }
  }
}
