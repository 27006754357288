<template>
  <div
    id="app"
    :class="{ 'is-dark-theme': ($route.name || '').includes('ide') }"
  >
    <div id="modals">
      <ModalConfirmAction
        v-if="hasMounted"
        id="confirm-action-modal"
        ref="confirmActionModal"
      />
    </div>

    <router-view v-slot="{ Component, route }">
      <component
        :is="Component"
        :key="route.name"
      />
    </router-view>

    <GlobalToast />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'

import components from './components'

export default defineComponent({
  name: 'App',

  components: {
    ...components(
      'GlobalToast',
      'ModalConfirmAction'
    )
  },

  provide () {
    return {
      confirmActionModal: computed(() => this.confirmActionModal)
    }
  },

  setup () {
    const confirmActionModal = ref(null)

    return {
      confirmActionModal
    }
  },

  data () {
    return {
      hasMounted: false
    }
  },

  mounted () {
    this.hasMounted = true
  }
})
</script>

<style lang="scss">
  @import '@/style.scss';
</style>
