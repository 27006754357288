
import {
  parseTrigger,
  getTriggerBase,
  createTriggersModules,
  translateTriggerPayload
} from '../pages/triggers/utils/triggersModules'

export default {
  state: {
    trigger: null,
    triggers: [],
    removingTriggerID: null
  },

  getters: {
    getTrigger: state => state.trigger,
    getTriggers: state => state.triggers,
    getTriggersModules: state => createTriggersModules(structuredClone(state.triggers)),
    getRemovingTriggerID: state => state.removingTriggerID
  },

  mutations: {
    SET_TRIGGER: (state, payload) => (state.trigger = payload ?? null),
    SET_TRIGGERS: (state, payload) => (state.triggers = payload),
    SET_REMOVING_TRIGGER_ID: (state, payload) => (state.removingTriggerID = payload)
  },

  actions: {
    async searchTrigger ({ commit }, triggerID) {
      try {
        const { appID, tableID } = window.$route.params

        const response = await window
          .$request()
          .get(`database/${appID}/triggers/${tableID}/get/${triggerID}`)

        const trigger = translateTriggerPayload(response.data)

        commit('SET_TRIGGER', trigger)
      } catch (error) {
        console.error('[searchTrigger]', error)

        window.$toast({
          color: 'error',
          message: ''
        })
      }
    },

    async searchTriggers ({ commit }, tableID = null) {
      try {
        const { appID } = window.$route.params

        if (typeof tableID !== 'number') {
          return window.$toast({
            color: 'yellow',
            message: 'Falha na busca dos triggers'
          })
        }

        const response = await window
          .$request()
          .get(`database/${appID}/triggers/${tableID}`)

        commit('SET_TRIGGERS', response.data.map(trigger => translateTriggerPayload(trigger)))
      } catch (error) {
        console.error('[getTriggers]', error)
      }
    },

    async deleteTrigger ({ commit }, triggerID) {
      commit('SET_REMOVING_TRIGGER_ID', triggerID)

      try {
        const { appID, tableID } = window.$route.params

        const response = await window
          .$request()
          .delete(`database/${appID}/triggers/${tableID}/${triggerID}`)

        commit('SET_TRIGGERS', response.data)

        window.$toast({
          color: 'success',
          message: 'Trigger removido com sucesso'
        })
      } catch (error) {
        console.log('[deleteTrigger]', error)

        window.$toast({
          color: 'error',
          message: 'Falha ao remover o trigger'
        })
      } finally {
        commit('SET_REMOVING_TRIGGER_ID', null)
      }
    },

    async toggleTriggerStatus ({ commit }, { status, triggerID }) {
      try {
        const { appID, tableID } = window.$route.params

        const route = {
          0: 'pause',
          1: 'resume'
        }[status]

        const response = await window
          .$request()
          .put(`database/${appID}/triggers/${tableID}/${triggerID}/${route}`)

        commit('SET_TRIGGERS', response.data)

        window.$toast({
          color: 'success',
          message: 'Status atualizado com sucesso'
        })
      } catch (error) {
        console.log('[toggleTriggerStatus]', error)

        window.$toast({
          color: 'error',
          message: 'Falha na alteração do status do trigger'
        })
      }
    },

    patchTrigger ({ commit, state }, { triggerID, property, value }) {
      const updatedTriggers = structuredClone(state.triggers)
        .map(trigger => {
          if (trigger.id !== triggerID) return trigger

          return {
            ...trigger,
            [property]: value
          }
        })

      commit('SET_TRIGGERS', updatedTriggers)
    },

    patchCurrentTrigger ({ commit, state }, { key, value }) {
      const trigger = Object.assign(structuredClone(state.trigger), {
        [key]: value
      })

      commit('SET_TRIGGER', trigger)
    },

    buildTrigger ({ commit }, { model, actionType }) {
      const triggerBase = getTriggerBase({
        model: model ?? 'email', actionType
      })

      commit('SET_TRIGGER', triggerBase)

      return triggerBase
    },

    async createTrigger ({ commit, state }, { done, fail }) {
      try {
        const { appID, tableID } = window.$route.params

        const trigger = structuredClone(state.trigger)

        const triggerType = trigger.action_type

        const parsedTrigger = parseTrigger({ model: triggerType, content: trigger })

        delete parsedTrigger.action_type

        const response = await window
          .$request()
          .post(`/database/${appID}/triggers/${tableID}/${triggerType}`, parsedTrigger)

        commit('SET_TRIGGER', null)
        commit('SET_TRIGGERS', response.data)

        done(response.data)

        window.$toast({
          color: 'success',
          message: 'Trigger criado com sucesso'
        })
      } catch (error) {
        console.error('[createTrigger]', error)

        fail(error)

        window.$toast({
          color: 'error',
          message: 'Falha na criação do trigger'
        })
      }
    },

    async updateTrigger ({ commit, state }, { done, fail }) {
      try {
        const { appID, tableID } = window.$route.params

        const trigger = structuredClone(state.trigger)

        const { action_type: triggerType, id } = trigger

        const parsedTrigger = parseTrigger({ model: triggerType, content: trigger })

        delete parsedTrigger.id
        delete parsedTrigger.action_type

        const response = await window
          .$request()
          .put(`/database/${appID}/triggers/${tableID}/${triggerType}/${id}`, parsedTrigger)

        commit('SET_TRIGGER', null)
        commit('SET_TRIGGERS', state.triggers.map(singleTrigger => {
          if (singleTrigger.id === trigger.id) {
            return translateTriggerPayload(response.data)
          }

          return singleTrigger
        }))

        done(response.data)

        window.$toast({
          color: 'success',
          message: 'Atualização concluída com sucesso'
        })
      } catch (error) {
        console.error('[createTrigger]', error)

        fail(error)

        window.$toast({
          color: 'error',
          message: 'Falha na atualização do trigger'
        })
      }
    }
  }
}
