export default {
  state: {
    myCode: '<html></html>',

    tutorials: [
      {
        title: 'Importando o script do checkout',
        description: 'Veja como importar o script que é responsável por conectar nosso checkout a sua plataforma.',
        anchor: 'https://www.loom.com/share/832cfc981cb94b4c875c3ae19359d840?t=158'
      }, {
        title: 'Instalando o módulo',
        description: 'Instale o módulo da increazy para que tenhamos uma conexão especial com a sua plataforma.',
        anchor: 'https://www.loom.com/share/832cfc981cb94b4c875c3ae19359d840?t=231'
      }, {
        title: 'Dados de usuário e carrinho',
        description: 'Em um arquivo global do seu layout, defina dois campos ocultos com os dados do cliente e carrinho.',
        anchor: 'https://www.loom.com/share/832cfc981cb94b4c875c3ae19359d840?t=342'
      }
    ],

    steps: [
      {
        done: 1,
        name: 'change-apearence',
        label: 'Estilize a aparência do seu checkout',
        route: '/settings/1'
      }, {
        done: 1,
        name: 'edit-plans',
        label: 'Crie e edite planos',
        route: '/settings/1'
      }, {
        done: 1,
        name: 'change-methods',
        label: 'Configure os métodos de recebimento',
        route: '/settings/1'
      }, {
        done: 1,
        name: 'save-info',
        label: 'Cadastre suas informações',
        route: '/settings/1'
      }, {
        done: 0,
        name: 'checkout-publish',
        label: 'Publique o seu checkout',
        route: '/settings/1'
      }
    ]
  },

  getters: {
    myCode: state => state.myCode,

    doneSteps: state => state.steps.filter(({ done }) => done).length + 1
  },

  mutations: {
    setMyCode: (state, payload) => {
      state.myCode = payload
    }
  },

  actions: {
    setMyCode: ({ commit }, payload) => {
      commit('setMyCode', payload)
    }
  }
}
