export default {
  namespaced: true,

  state: {
    sync: {
      date: '1997-12-19 18:30:12',
      email: 'john.doe@increazy.com'
    },
    advanced: {},
    loadingCache: false
  },

  getters: {
    getSync: state => state.sync,
    getAdvanced: state => state.advanced,
    getLoadingCache: state => state.loadingCache
  },

  mutations: {
    SET_SYNC: (state, value) => (state.sync = value),
    SET_ADVANCED: (state, value) => (state.advanced = value),
    SET_LOADING_CACHE: (state, value) => (state.loadingCache = value)
  },

  actions: {
    async searchAdvanced ({ commit }) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request()
          .get(`apps/${id}/settings`)

        commit('SET_ADVANCED', response.data)
      } catch (error) {
        console.log('[searchAdvanced]', error)
      }
    },

    async updateAdvanced ({ commit }, { data, done, fail }) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request()
          .put(`apps/${id}/settings`, data)

        commit('SET_ADVANCED', response.data.settings)
        done(response.data)
      } catch (error) {
        console.log('[updateAdvanced]', error)
        fail(error)
      }
    },

    async updateCache ({ commit }) {
      try {
        commit('SET_LOADING_CACHE', true)

        await window
          .$request()
          .put(`/apps/${window.$route.params.appID}/settings/version`)

        window.$toast({
          color: 'success',
          message: 'Cache atualizado com sucesso!'
        })
      } catch (e) {
        console.log('[updateCache]')
      } finally {
        commit('SET_LOADING_CACHE', false)
      }
    },

    async getSync ({ commit, state, rootState }) {
      try {
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const response = await window
          .$request('indexer')
          .get(`sync/${checkoutID}`)

        commit('SET_SYNC', response.data)
      } catch (error) {
        console.error('[searchSync]', error)
      }
    },

    async searchSync ({ commit, state, rootState }, eraseCatalog) {
      try {
        const { email } = rootState.auth?.user
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const response = await window
          .$request('indexer')
          .post(`sync/${checkoutID}`, {
            email,
            ...(eraseCatalog && {
              delete: true
            })
          })

        commit('SET_SYNC', response.data?.control)

        window.$toast({
          color: 'success',
          message: 'Sincronização executada com sucesso'
        })
      } catch (error) {
        console.error('[searchSync]', error)

        window.$toast({
          color: 'error',
          message: 'Falha na sincronização'
        })
      }
    }
  }
}
