export default (app) => {
  app.mixin({
    computed: {
      $menuProfileOptions () {
        return [
          { routeName: 'settings-profile', title: 'Perfil' },
          { routeName: 'settings-advanced', title: 'Avançado' },
          { routeName: 'settings-permissions', title: 'Permissões e usuários' }
        ]
      }
    }
  })
}
