
export default {
  state: {
    editID: null,
    removeID: null,
    searchRules: [],
    searchPercentage: 0,
    hasSettingsPanel: null
  },

  getters: {
    getEditID: state => state.editID,
    getRemoveID: state => state.removeID,
    getSearchRules: state => state.searchRules,
    getSearchPercentage: state => state.searchPercentage
  },

  mutations: {
    SET_EDIT_ID: (state, payload) => (state.editID = payload),
    SET_REMOVE_ID: (state, payload) => (state.removeID = payload),
    SET_SEARCH_PERCENTAGE: (state, payload) => (state.searchPercentage = payload),
    SET_SEARCH_RULES: (state, payload) => {
      state.editID = null
      state.removeID = null

      state.searchRules = payload.sort((ruleA, ruleB) => {
        if (ruleB.status === ruleA.status) {
          return ruleB.boost - ruleA.boost
        }

        return ruleB.status - ruleA.status
      })
    },
    SET_HAS_SETTINGS_PANEL: (state, payload) => {
      state.hasSettingsPanel = payload ?? null
    }
  },

  actions: {
    async getSearchRules ({ commit }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`apps/${appID}/search-rules`)

        commit('SET_SEARCH_RULES', response.data)
      } catch (error) {
        console.error('[getSearchRules]', error)

        window.$toast({
          color: 'error',
          message: error.message ?? 'Falha na busca das regras'
        })
      }
    },

    async createSearchRules ({ commit }, { data, done, fail }) {
      try {
        const { appID } = window.$route.params

        if (!data.boost) {
          data.boost = '1'
        }

        const response = await window
          .$request()
          .post(`apps/${appID}/search-rule`, data)

        commit('SET_SEARCH_RULES', response.data)

        done(response.data)
      } catch (error) {
        fail(error)

        console.error('[createSearchRules]', error)
      }
    },

    async toggleSearchRuleStatus ({ commit, state }, { id: searchRuleID, action }) {
      try {
        const { appID } = window.$route.params

        const routeMethod = {
          1: 'pause',
          0: 'resume'
        }[action]

        const response = await window
          .$request()
          .put(`apps/${appID}/search-rule/${searchRuleID}/${routeMethod}`)

        commit('SET_SEARCH_RULES', response.data)

        window.$toast({
          color: 'success',
          message: 'Status da regra alterado com sucesso'
        })
      } catch (error) {
        console.log('[createSearchRules]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao atualizar status da regra'
        })
      }
    },

    async deleteSearchRule ({ commit }, ruleID) {
      commit('SET_REMOVE_ID', ruleID)

      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .delete(`apps/${appID}/search-rule/${ruleID}`)

        commit('SET_SEARCH_RULES', response.data)

        window.$toast({
          color: 'success',
          message: 'Regra removida com sucesso'
        })
      } catch (error) {
        console.error('[deleteSearchRule]', error)

        window.$toast({
          color: 'error',
          message: 'Erro na remoção da regra'
        })
      } finally {
        commit('SET_REMOVE_ID', null)
      }
    },

    async updateRule ({ commit }, { done, fail, data }) {
      try {
        const { appID } = window.$route.params

        const { id: ruleID } = data

        if (!data.boost) {
          data.boost = '1'
        }

        const response = await window
          .$request()
          .put(`/apps/${appID}/search-rule/${ruleID}`, data)

        commit('SET_SEARCH_RULES', response.data)

        done(response.data)
      } catch (error) {
        console.error('[updateRule]', error)

        fail(error)
      }
    },

    updateSingleRule ({ commit, state }, { id, key, value }) {
      const updatedRules = [...state.searchRules]
        .map(rule => {
          return rule.id === id
            ? { ...rule, [key]: value }
            : rule
        })

      commit('SET_SEARCH_RULES', updatedRules)
    },

    async getSearchPercentage ({ commit }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`/apps/${appID}/search-rule/percentage`)

        commit('SET_SEARCH_PERCENTAGE', response.data?.percentage ?? 0)
      } catch (error) {
        console.error('[getSearchPercentage]', error)

        window.$toast({
          color: 'success',
          message: 'Falha na busca da porcentagem de busca'
        })
      }
    },

    async updateSearchPercentage ({ commit }, { data, done, fail }) {
      try {
        const { appID } = window.$route.params

        const value = Math.min(Math.max(parseInt(data.percentage, 10), 0), 100)

        const response = await window
          .$request()
          .put(`apps/${appID}/search-rule/percentage`, { value })

        commit('SET_SEARCH_PERCENTAGE', response.data?.percentage || 0)

        done(response.data)
      } catch (error) {
        console.error('[updateSearchPercentage]', error)

        fail(error)
      }
    }
  }
}
