import translateConditions from '@/core/utils/translate-conditions'

export default {
  state: {
    xml: {},
    xmls: [],
    actionTypeXML: '',
    xmlTemplates: [],
    xmlContent: '',
    xmlDefaultContent: '',
    xmlConditions: []
  },

  getters: {
    getXml: state => state.xml,
    getXmls: state => state.xmls,
    getActionTypeXML: state => state.actionTypeXML,
    getHasXml: state => !!Object.keys(state.xml).length,
    getXmlTemplates: state => state.xmlTemplates,
    getXmlContent: state => state.xmlContent,
    getXmlDefaultContent: state => state.xmlDefaultContent
  },

  mutations: {
    SET_XML: (state, value) => (state.xml = value),
    SET_XMLS: (state, payload) => (state.xmls = payload),
    SET_XML_TEMPLATES: (state, value) => (state.xmlTemplates = value),
    SET_XML_CONTENT: (state, value) => (state.xmlContent = value),
    SET_XML_DEFAULT_CONTENT: (state, value) => (state.xmlDefaultContent = value),
    SET_XML_CONDITIONS: (state, value) => (state.xmlConditions = value)
  },

  actions: {
    clearXml ({ commit }) {
      commit('SET_XML', {})
    },

    setXml ({ commit }, data) {
      commit('SET_XML', {
        id: data?.id || undefined,
        name: data?.name || '',
        template: data?.template || '',
        all_skus: data?.all_skus || 0,
        content: data?.content || '',
        conditions: data?.conditions || []
      })
    },

    async searchXml ({ commit }) {
      try {
        const { appID, xmlID } = window.$route.params

        const response = await window
          .$request()
          .get(`/migration/${appID}/xml/${xmlID}`)

        commit('SET_XML', response.data)
      } catch (e) {
        console.log('[searchXml]', e)

        window.$toast({
          color: 'error',
          message: 'Arquivo xml não encontrado'
        })
      }
    },

    async searchXmlTemplates ({ commit }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`/migration/${appID}/xml/templates`)

        commit('SET_XML_TEMPLATES', response.data?.templates || [])
      } catch (e) {
        console.log('[searchXmlTemplates]', e)
      }
    },

    setXmlContent ({ commit }, value) {
      commit('SET_XML_CONTENT', value)
    },

    async searchXmlByTemplate ({ commit }, template) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`/migration/${appID}/xml/templates/${template}`)

        commit('SET_XML_DEFAULT_CONTENT', response.data?.content || '')
        commit('SET_XML_CONTENT', response.data?.content || '')
      } catch (e) {
        console.log('[searchXmlTemplates]', e)
      }
    },

    async searchXmls ({ commit }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`/migration/${appID}/xml`)

        commit('SET_XMLS', response.data?.list)
      } catch (error) {
        console.log('[searchXmls]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao buscar os xmls'
        })
      }
    },

    async createOrUpdateXml ({ commit, state }, { data, done, fail }) {
      try {
        const { appID, xmlID } = window.$route.params
        const conditions = translateConditions(data)
        const template = state.xmlDefaultContent !== state.xmlContent ? 'custom' : data.template

        data = {
          all_skus: +data?.all_skus || 0,
          conditions,
          name: `${data.name.replace('.xml', '')}.xml`,
          template,
          content: state.xmlContent
        }

        if (!conditions) {
          delete data.conditions
        }

        const method = xmlID !== 'new' ? 'put' : 'post'
        const url = xmlID !== 'new' ? xmlID : 'create'
        const response = await window.$request()[method](
          `/migration/${appID}/xml/${url}`, data
        )

        commit('SET_XMLS', (response.data?.list || []))
        done(response.data)
      } catch (e) {
        fail(e)
        console.error('[createXML]', e)
        window.$toast({
          color: 'error',
          message: 'Falha na criação do XML'
        })
      }
    },

    async setXmlStatus ({ commit }, { id, endpoint }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .put(`migration/${appID}/xml/${id}/${endpoint}`)

        commit('SET_XMLS', response.data?.list)

        window.$toast({
          color: 'success',
          message: 'O status do xml foi atualizado com sucesso'
        })
      } catch (error) {
        window.$toast({
          color: 'error',
          message: 'Não foi possível atualizar o status do xml'
        })

        console.error('[setXmlStatus]', error)
      }
    }
  }
}
