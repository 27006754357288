export default [
  {
    path: '/ide/:appID',
    meta: {
      hasSidebar: true,
      requireLogin: true
    },
    component: () => import(
      /* webpackChunkName: "ide-code" */ './pages'
    ),
    children: [
      {
        path: '',
        name: 'ide-code',
        component: () => import(
          /* webpackChunkName: "ide-code-main" */ './pages/code'
        )
      }
    ]
  }
]
