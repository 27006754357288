
import logs from './logs'
import mails from './mail'
import redirects from './redirects'
import elasticCatalog from './elastic-catalog'
import searchOptions from './search-options'
import xmls from './xml'
import ecommerceDetails from './ecommerce-details'
import smtp from './smtp'
import syslog from './syslog'
import clusters from './clusters'

export default {
  namespaced: true,

  state: {
    ...logs.state,
    ...mails.state,
    ...redirects.state,
    ...searchOptions.state,
    ...elasticCatalog.state,
    ...xmls.state,
    ...ecommerceDetails.state,
    ...smtp.state,
    ...syslog.state,
    ...clusters.state
  },

  getters: {
    ...logs.getters,
    ...mails.getters,
    ...redirects.getters,
    ...searchOptions.getters,
    ...elasticCatalog.getters,
    ...xmls.getters,
    ...ecommerceDetails.getters,
    ...smtp.getters,
    ...syslog.getters,
    ...clusters.getters
  },

  mutations: {
    ...logs.mutations,
    ...mails.mutations,
    ...redirects.mutations,
    ...searchOptions.mutations,
    ...elasticCatalog.mutations,
    ...xmls.mutations,
    ...ecommerceDetails.mutations,
    ...smtp.mutations,
    ...syslog.mutations,
    ...clusters.mutations
  },

  actions: {
    ...logs.actions,
    ...mails.actions,
    ...redirects.actions,
    ...searchOptions.actions,
    ...elasticCatalog.actions,
    ...xmls.actions,
    ...ecommerceDetails.actions,
    ...smtp.actions,
    ...syslog.actions,
    ...clusters.actions
  }
}
