export default [
  {
    path: '/401',
    name: 'auth-401',
    meta: { isGlobal: true },
    component: () => import(/* webpackChunkName: "auth-401" */ './pages/401')
  },
  {
    path: '/404',
    name: 'auth-404',
    meta: { isGlobal: true },
    component: () => import(/* webpackChunkName: "auth-404" */ './pages/404')
  }
]
