
export default {
  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async postElastic ({ commit }, { app, action, entity }) {
      return await window
        .$request()
        .post('https://indexer.test.increazy.com/magento2/test/product', {
          app,
          action,
          entity
        })
    },

    async createElasticUpdate ({ commit }, { app, action, entities, path }) {
      try {
        const requests = entities.map(entity => {
          return window
            .$request()
            .post(`https://indexer.test.increazy.com/magento2/test/${path}`, {
              app,
              action,
              entity
            })
        })

        Promise.all(requests)
          .then(response => console.log(response))
      } catch (e) {
        console.error(e, 'error')
      }
    }
  }
}
