
function hasSearchResults ({ codes, files }) {
  return !!(codes.length || files.length)
}

function mapSearchResults ({ codes, files }) {
  return [
    ...codes, ...files
  ]
}

export default {
  state: {
    term: '',
    loadingSearch: false,
    search: {
      codes: [],
      files: []
    },
    abortController: null
  },

  getters: {
    getTerm: state => state.term,
    getLoadingSearch: state => state.loadingSearch,
    getSearch: state => mapSearchResults(state.search),
    hasSearch: state => hasSearchResults(state.search),
    getAbortController: state => state.abortController,
    hasEmptySearch: state => !!state.term.length && !hasSearchResults(state.search)
  },

  mutations: {
    SET_TERM: (state, payload) => (state.term = payload.trim()),
    SET_GLOBAL_SEARCH: (state, payload) => {
      state.search = payload
      state.abortController = null
    },
    SET_LOADING_SEARCH: (state, payload) => (state.loadingSearch = payload),
    SET_ABORT_CONTROLLER: (state, payload) => (state.abortController = payload)
  },

  actions: {
    setTerm ({ commit, dispatch, state }, term = null) {
      const searchTerm = term ?? ''

      commit('SET_TERM', searchTerm)

      if (!searchTerm.length) {
        commit('SET_GLOBAL_SEARCH', {
          codes: [], files: []
        })

        return
      }

      state.abortController && state.abortController.abort()

      searchTerm.length > 2 && dispatch('globalSearch')
    },

    async globalSearch ({ commit, state }) {
      commit('SET_LOADING_SEARCH', true)

      try {
        const { appID } = window.$route.params

        const { term } = state

        const controller = new AbortController()

        commit('SET_ABORT_CONTROLLER', controller)

        const response = await window
          .$request()
          .post(`ide/${appID}/global-search`, { term }, {
            signal: controller.signal
          })

        response && commit('SET_GLOBAL_SEARCH', response.data)
      } catch (error) {
        if (error?.code === 'ERR_CANCELED') {
          return
        }

        console.error('[globalSearch]', error)

        window.$toast({
          color: 'error',
          message: 'Falha na busca'
        })
      }

      commit('SET_LOADING_SEARCH', false)
    }
  }
}
