
import formData from '@/core/utils/toformdata'

function validateInvalidRequestData (register, scheme) {
  const defaultValues = {
    string: '',
    double: 0,
    object: null,
    boolean: false
  }

  const newObject = Object
    .keys(register)
    .reduce((acc, key) => {
      const currentType = scheme.find(item => item.attribute === key).type

      if (currentType === 'file' && register[key].size === 0) {
        return acc
      }

      if (!register[key]) {
        return [...acc, [key, defaultValues[currentType]]]
      }

      return [...acc, [key, register[key]]]
    }, [])

  return Object.fromEntries(newObject)
}

export default {
  state: {
    row: true,
    rows: [],
    selectedRow: {
      mode: '',
      data: {},
      updateID: null,
      isFileMode: false
    }
  },

  getters: {
    getRow: state => state.row,
    getRows: state => state.rows,
    getSelectedRow: state => state.selectedRow,
    hasSelectedRow: state => !!Object.keys(state.selectedRow.data || {}).length
  },

  mutations: {
    SET_ROW: (state, value) => (state.row = value),
    SET_ROWS: (state, value) => (state.rows = value),
    SET_SELECTED_ROW: (state, value = {}) => {
      const { mode, data, isFileMode, updateID } = value

      state.selectedRow = {
        mode: mode ?? '',
        data: data ?? {},
        updateID: updateID ?? null,
        isFileMode: isFileMode ?? false
      }
    }
  },

  actions: {
    setRows ({ commit }, data) {
      commit('SET_ROWS', data)
    },

    createRowTemplate ({ commit }, { columnMap, columnValuesMap }) {
      const valueKeys = Object.keys(columnValuesMap ?? {})

      const mode = valueKeys.includes('id')
        ? 'update'
        : 'create'

      let iterationsLimit = mode === 'update'
        ? valueKeys.length - 1
        : valueKeys.length

      for (let i = 0, len = columnMap.length; i < len && iterationsLimit; i++) {
        const currentColumItem = columnMap.at(i)

        if (!valueKeys.includes(currentColumItem.attribute)) continue

        iterationsLimit--

        const _value = columnValuesMap[currentColumItem.attribute]

        const value = currentColumItem.type === 'double'
          ? _value || 0
          : _value ?? ''

        columnMap.splice(i, 1, {
          ...currentColumItem, value
        })
      }

      const isFileMode = columnMap.some(({ type }) => type === 'file')

      commit('SET_SELECTED_ROW', {
        mode,
        isFileMode,
        data: columnMap,
        updateID: columnValuesMap.id ?? null
      })
    },

    async searchRows ({ commit }, data = {}) {
      try {
        const { appID } = window.$route.params

        data.params = {
          page: 1,
          per_page: 6
        }

        const response = await window
          .$request()
          .get(`/database/${appID}/crud/${data.name}`, { params: { ...data.params } })

        commit('SET_ROWS', response.data)
      } catch (error) {
        console.log('[searchRows]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao buscar os dados da tabela'
        })
      }
    },

    async createRegister ({ commit, state }, { done, fail, data: { tableName, tableData } }) {
      try {
        const { appID } = window.$route.params

        const treatedData = validateInvalidRequestData(tableData, state.selectedRow.data)

        const hasSomeFile = Object
          .keys(treatedData)
          .some(key => treatedData[key] instanceof File)

        const _data = hasSomeFile
          ? formData(treatedData)
          : treatedData

        const response = await window
          .$request()
          .post(`database/${appID}/crud/${tableName}/create`, _data)

        commit('SET_ROWS', {
          ...state.rows,
          rows: [].concat(response.data.row, state.rows.rows)
        })

        done(response.data)
      } catch (error) {
        console.log('[createRegister]', error)

        fail(error)
      }
    },

    async updateRegister ({ commit, state }, { done, fail, data: { tableName, tableData, updateID } }) {
      try {
        const { appID } = window.$route.params

        const validatedRequestData = validateInvalidRequestData(tableData, state.selectedRow.data)

        const _data = state.selectedRow.isFileMode
          ? formData(validatedRequestData)
          : validatedRequestData

        const method = state.selectedRow.isFileMode
          ? 'post'
          : 'put'

        const response = await window
          .$request()[method](`database/${appID}/crud/${tableName}/${updateID}`, _data)

        commit('SET_ROWS', {
          ...state.rows,
          rows: state.rows.rows.map(row => {
            return row.id === response.data.row.id
              ? response.data.row
              : row
          })
        })

        done(response.data)
      } catch (error) {
        fail(error)

        console.error('[updateRegister]', error)
      }
    },

    async deleteRegister ({ commit, state }, { tableName, registerID }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .delete(`database/${appID}/crud/${tableName}/${registerID}`)

        commit('SET_ROWS', {
          ...state.rows,
          rows: state.rows.rows.filter(({ id }) => id !== registerID)
        })

        window.$toast({
          color: 'success',
          message: 'Registro removido com sucesso'
        })
      } catch (error) {
        console.error('[deleteRegister]', error)

        window.$toast({
          color: 'error',
          message: 'Falha ao remover o registro'
        })
      }
    }
  }
}
