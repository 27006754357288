export default {
  state: {
    hasTable: true,
    table: {},
    tables: []
  },

  getters: {
    getTable: state => state.table,
    getTables: state => state.tables,
    getHasTable: state => !!Object.keys(state.table).length,
    getColumns: state => state.table?.columns || []
  },

  mutations: {
    SET_TABLE: (state, value) => (state.table = value),
    SET_TABLES: (state, value) => (state.tables = value),
    SET_COLUMNS (state, value) {
      if (Array.isArray(value)) {
        state.table.columns = [...value]
        return
      }

      state.table?.columns.push(value)
    }
  },

  actions: {
    clearTable ({ commit }) {
      commit('SET_TABLE', {})
    },

    setTable ({ commit }, data) {
      commit('SET_TABLE', {
        name: data?.name || '',
        columns: data?.columns || []
      })

      commit('SET_COLUMNS', data?.columns || [])
    },

    setColumns ({ commit }, data) {
      commit('SET_COLUMNS', data)
    },

    async searchTable ({ commit, dispatch }, id) {
      try {
        const { appID } = window.$route.params
        dispatch('setTable', {})

        const response = await window
          .$request()
          .get(`/database/${appID}/tables/${id}`)

        commit('SET_TABLE', response.data)
      } catch (e) {
        dispatch('clearTable')
        console.log('[searchTable]', e)

        window.$toast({
          color: 'error',
          message: 'Tabela não encontrada'
        })
      }
    },

    async searchTables ({ commit }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`/database/${appID}/tables`)

        commit('SET_TABLES', response.data?.list)
      } catch (error) {
        console.log('[searchTables]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao buscar as tabelas'
        })
      }
    },

    async createOrUpdateTable ({ commit, state }, { data, done, fail }) {
      try {
        const { appID } = window.$route.params
        const id = data.id || ''

        data = {
          name: data.name,
          columns: [
            ...state.table.columns
          ]
        }

        const method = id ? 'put' : 'post'
        const url = id || 'create'
        const response = await window.$request()[method](
          `/database/${appID}/tables/${url}`, data
        )

        commit('SET_TABLES', (response.data?.list || []))
        done(response.data)
      } catch (e) {
        fail(e)
        console.error('[creacreateOrUpdateTableteXML]', e)
        window.$toast({
          color: 'error',
          message: 'Falha na criação da tabela'
        })
      }
    },

    async setTableStatus ({ commit }, { id, endpoint }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .put(`database/${appID}/tables/${id}/${endpoint}`)

        commit('SET_TABLES', response.data?.list)

        window.$toast({
          color: 'success',
          message: 'O status da tabela foi atualizado'
        })
      } catch (error) {
        window.$toast({
          color: 'error',
          message: 'Não foi possível atualizar o status da tabela'
        })

        console.error('[setTableStatus]', error)
      }
    }
  }
}
