
export default function encapsulate (list = []) {
  return list.reduce((acc, cur) => {
    if (cur?.soon) return acc

    if (cur.soon || ['manifest', 'robots'].includes(cur.name)) return acc

    if (cur.folder === 'root' || cur.folder === '') {
      const include = ['', 'root']

      const firstNonRootItemIndex = acc.findIndex(item => include.includes(item?.folder) || include.includes(item?.name))

      if (firstNonRootItemIndex === -1) {
        return acc.concat(cur)
      } else {
        acc.splice(firstNonRootItemIndex, 0, cur)

        return acc
      }
    }

    const folder = acc.find(item => item.name === cur?.folder)

    if (folder) {
      folder.links.push(cur)

      return acc
    }

    return acc.concat({
      name: cur?.folder,
      folder: cur?.folder,
      folder_human: cur?.folder_human,
      links: [cur]
    })
  }, [])
}
