import { mapActions, mapGetters } from 'vuex'

export default (app) => {
  app.mixin({
    computed: {
      ...mapGetters({
        // $loading: "global/getLoader",
        $user: 'auth/getUser',
        $app: 'apps/getApp'
      })
    },

    methods: {
      ...mapActions({
        $logout: 'auth/logout'
      })
    }
  })
}
