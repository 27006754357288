import { createStore } from 'vuex'

export default createStore({
  modules: {
    global: {
      namespaced: true,
      state: {
        module: '',
        app: '',
        isDarkTheme: false
      },

      getters: {
        getModule: state => state.module,
        getApp: state => state.app
      },

      mutations: {
        setActiveModule: (state, module) => {
          state.module = module
        },
        setApp: (state, app) => {
          state.app = app
        }
      },

      actions: {
        searchApp: ({ commit, state }) => {},
        setActiveModule: ({ commit, state }, module) => {
          commit('setActiveModule', module)
        },
        setApp: ({ commit, state }, app) => {
          commit('setApp', app)
        }
      }
    }
  }
})
