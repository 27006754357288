
function AxiosRequest (type = null) {
  const windowRequest = window.$request(type)

  return {
    get: windowRequest.get,
    put: windowRequest.put,
    post: windowRequest.post,
    delete: windowRequest.delete
  }
}

async function storeItems (dispatch, method, { items, kindOf, fail }) {
  if (items.length) {
    await dispatch(method, { fail, data: items, kindOf })
  }
}

export default {
  state: {
    bin: [],
    loadRecycleBin: false
  },

  getters: {
    getBin: state => state.bin,
    isRecycleBinLoaded: state => state.loadRecycleBin,
    getItemsInTrash: state => {
      return state.bin.reduce((bin, group) => bin.concat(group.payload), [])
    }
  },

  mutations: {
    SET_BIN: (state, { group, payload }) => {
      state.bin = [
        ...state.bin.filter(binGroup => binGroup.group !== group), {
          group, payload
        }
      ]
    },

    SET_LOAD_BIN: (state, payload) => (state.loadRecycleBin = payload)
  },

  actions: {
    loadRecycleBin ({ commit }, payload = true) {
      commit('SET_LOAD_BIN', payload)
    },

    async searchBin ({ commit }, { group }) {
      const { appID } = window.$route.params

      const response = await AxiosRequest().get(`apps/${appID}/${group}/trash`)

      commit('SET_BIN', {
        group: group,
        payload: response.data
      })
    },

    async searchTrashFiles ({ dispatch }) {
      try {
        await dispatch('searchBin', { group: 'file' })
      } catch (error) {
        console.error('[searchTrashFiles]', error)
      }
    },

    async searchTrashCodes ({ dispatch }) {
      try {
        await dispatch('searchBin', { group: 'code' })
      } catch (error) {
        console.error('[searchTrashCodes]', error)
      }
    },

    async handleRecycle ({ commit }, { data, action, fail, kindOf }) {
      try {
        const { appID } = window.$route.params

        const response = await AxiosRequest().post(`apps/${appID}/${kindOf}/recover`, {
          [`${kindOf}s`]: data
        })

        commit('SET_BIN', {
          group: kindOf,
          payload: response.data
        })
      } catch (error) {
        console.log('[handleRecycle]', error)

        fail('Falha na recuperação dos arquivos')
      }
    },

    async handleRemove ({ commit }, { data, action, fail, kindOf }) {
      try {
        const { appID } = window.$route.params

        const response = await AxiosRequest().delete(`apps/${appID}/${kindOf}/remove`, {
          data: {
            [`${kindOf}s`]: data
          }
        })

        commit('SET_BIN', {
          group: kindOf,
          payload: response.data
        })
      } catch (error) {
        fail()

        console.log('[handleRemove]', error)

        window.$toast({
          color: 'error',
          message: 'Falha na exclusão dos arquivos'
        })
      }
    },

    async trashGeneralEventHandler ({ commit, getters, dispatch }, { action, data: { identifiers }, done, fail }) {
      const dataMap = identifiers.map(id => getters.getItemsInTrash.find(item => item.id === +id))

      const typeSet = new Set(['page', 'layout', 'asset', 'element'])

      const { files, codes } = dataMap.reduce((acc, { type, id }) => {
        typeSet.has(type)
          ? acc.codes.push(id)
          : acc.files.push(id)

        return acc
      }, { files: [], codes: [] })

      const storeMethod = action === 'recover'
        ? 'handleRecycle'
        : 'handleRemove'

      await Promise.all([
        storeItems(dispatch, storeMethod, { items: files, kindOf: 'file', fail }),
        storeItems(dispatch, storeMethod, { items: codes, kindOf: 'code', fail })
      ]).then(() => done())

      if (action !== 'recover') return

      await Promise.all([
        dispatch('searchCodes'),
        dispatch('searchFiles')
      ])
    }
  }
}
