
export default {
  state: {
    logs: {},
    logsQueries: {},
    isLogsLoading: true
  },

  getters: {
    getLogs: state => state.logs,
    getLogsQueries: state => state.logsQueries,
    getIsLogsLoading: state => state.isLogsLoading
  },

  mutations: {
    SET_LOGS: (state, value) => (state.logs = value),
    SET_LOGS_QUERIES: (state, value) => (state.logsQueries = value),
    SET_IS_LOGS_LOADING: (state, value) => (state.isLogsLoading = value)
  },

  actions: {
    async searchLogs ({ commit, state }, data) {
      commit('SET_IS_LOGS_LOADING', true)

      try {
        const { appID } = window.$route.params

        const pageQueries = {
          ...state.logsQueries,
          ...data
        }

        commit('SET_LOGS_QUERIES', pageQueries)

        const queryParams = new URLSearchParams(pageQueries)

        const response = await window
          .$request()
          .get(`/apps/${appID}/user-log?${queryParams.toString()}`)

        commit('SET_LOGS', response.data)

        return response.data
      } catch (error) {
        console.error('[searchLogs]', error)

        window.$toast({
          color: 'error',
          message: 'Houve um erro ao buscar os logs'
        })
      } finally {
        commit('SET_IS_LOGS_LOADING', false)
      }
    },

    async filterLogs ({ dispatch }, { data, fail, done }) {
      try {
        if (!data.client) delete data.client

        const response = await dispatch('searchLogs', { ...data, page: 1 }) ?? false

        done(response)
      } catch (e) {
        fail(e)
      }
    }
  }
}
