
export default {
  state: {
    loadedConfig: false,
    ecommerceDetails: {}
  },

  getters: {
    isLoadedConfig: state => state.loadedConfig,
    getEcommerceDetails: state => state.ecommerceDetails,
    isMagentoPlatform: state => {
      return state.ecommerceDetails?.platform?.name.toLowerCase() === 'magento'
    }
  },

  mutations: {
    SET_LOADED_CONFIG: (state, payload) => (state.loadedConfig = payload),
    SET_ECOMMERCE_DETAILS: (state, payload) => (state.ecommerceDetails = payload)
  },

  actions: {
    async searchEcommerceDetails ({ commit, rootState }) {
      const checkoutID = rootState?.apps?.app?.settings?.checkout_id ?? null

      if (!checkoutID) return

      try {
        const response = await window
          .$request('ecommerce')
          .get(`/checkout/${checkoutID}/json`)

        commit('SET_ECOMMERCE_DETAILS', response.data)
      } catch (error) {
        console.error('[searchEcommerceDetails]', error)

        window.$toast({
          color: 'yellow',
          message: 'Falha na busca de informações do app.'
        })
      } finally {
        commit('SET_LOADED_CONFIG', true)
      }
    }
  }
}
