
export default (app) => {
  app.mixin({
    methods: {
      $hasSlot (name) {
        return !!this.$slots[name]
      }
    }
  })
}
