
export default {
  state: {
    clusters: [],
    cluster: {},
    hasCluster: false
  },

  getters: {
    getClusters: (state) => state.clusters,
    getCluster: (state) => state.cluster,
    getHasCluster: state => !!Object.keys(state.cluster).length
  },

  mutations: {
    SET_CLUSTERS: (state, value) => (state.clusters = value),
    SET_CLUSTER: (state, value) => (state.cluster = value)
  },

  actions: {
    setCluster ({ commit }, name) {
      commit('SET_CLUSTER', {
        name
      })
    },

    clearCluster ({ commit }, name) {
      commit('SET_CLUSTER', {})
    },

    async searchClusters ({ commit, state }) {
      try {
        const appID = window.$route.params.appID

        const response = await window
          .$request()
          .get(`cluster/${appID}/nodes?per_page=20`)

        commit('SET_CLUSTERS', response.data)
      } catch (error) {
        console.error('[searchClusters]', error)
      }
    },

    async createCluster ({ commit }, { data, fail, done }) {
      try {
        const appID = window.$route.params.appID

        const response = await window
          .$request()
          .post(`cluster/${appID}/nodes`, {
            name: data.name
          })

        commit('SET_CLUSTERS', response.data)
        done(response.data)
      } catch (error) {
        console.log('[createCluster]', error)
      }
    }
  }
}
