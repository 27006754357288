import storeContent from './content'
import storeSEO from './seo'

export default {
  namespaced: true,

  state: {
    ...storeContent.state,
    ...storeSEO.state
  },

  getters: {
    ...storeContent.getters,
    ...storeSEO.getters
  },

  mutations: {
    ...storeContent.mutations,
    ...storeSEO.mutations
  },

  actions: {
    ...storeContent.actions,
    ...storeSEO.actions
  }
}
