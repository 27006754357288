
export default (...attrs) => {
  const data = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {}
  }

  attrs.forEach((attr) => {
    data.state = { ...data.state, ...attr.state }
    data.getters = { ...data.getters, ...attr.getters }
    data.mutations = { ...data.mutations, ...attr.mutations }
    data.actions = { ...data.actions, ...attr.actions }
  })

  return data
}
