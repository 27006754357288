export default [
  {
    path: '/data-base/:appID',
    meta: {
      hasSidebar: true
    },
    children: [
      {
        path: '',
        name: 'data-base-tables-list',
        component: () => import(/* webpackChunkName: "data-base-tables-list" */ './pages/tables')
      },

      {
        path: 'table/:tableID',
        children: [
          {
            path: '',
            name: 'data-base-table-details',
            component: () => import(/* webpackChunkName: "data-base-table-details" */ './pages/table-details')
          },

          {
            path: 'triggers',
            name: 'data-base-triggers',
            component: () => import(/* webpackChunkName: "data-base-triggers" */ './pages/triggers')
          }
        ]
      }
    ]
  }
]
