import toFormData from '@/core/utils/toformdata'

export default {
  state: {
    redirects: {},
    editingID: null,
    removingID: null,
    isRedirectionsLoading: null
  },

  getters: {
    getRedirects: state => state.redirects,
    getEditingID: state => state.editingID,
    getRemovingID: state => state.removingID,
    getIsRedirectionsLoading: state => state.isRedirectionsLoading,
    getEditingRedirect: state => {
      return state.editingID
        ? state.redirects.list.find(({ id }) => id === state.editingID)
        : {}
    }
  },

  mutations: {
    SET_REDIRECTS: (state, value) => (state.redirects = value),
    SET_EDITING_ID: (state, value) => (state.editingID = value),
    SET_REMOVING_ID: (state, value) => (state.removingID = value),
    SET_REDIRECTIONS_LOADING: (state, value) => (state.isRedirectionsLoading = value)
  },

  actions: {
    async createRedirect ({ commit }, { data, done, fail }) {
      commit('SET_REDIRECTIONS_LOADING', true)

      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .post(`migration/${appID}/redirect/create`, data)

        commit('SET_EDITING_ID', null)
        commit('SET_REDIRECTS', response?.data || [])

        done(response.data)
      } catch (error) {
        fail(error)

        console.error('[createRedirect]', error)
      } finally {
        commit('SET_REDIRECTIONS_LOADING', false)
      }
    },

    async createByCSV ({ commit }, { data, done, fail }) {
      try {
        const { appID } = window.$route.params
        data = toFormData(data)

        const response = await window
          .$request()
          .post(`migration/${appID}/redirect/byCsv`, data)

        if (response.data.message) {
          fail(response.data.message)
        }

        commit('SET_REDIRECTS', response?.data || [])

        done(response?.data)
      } catch (error) {
        fail(error)
        console.error('[createByCSV]', error)
      }
    },

    async updateRedirect ({ commit }, { data, done, fail }) {
      try {
        const { appID } = window.$route.params

        const { redirectID, origin, target } = data

        const response = await window
          .$request()
          .put(`migration/${appID}/redirect/${redirectID}`, {
            origin, target
          })

        commit('SET_EDITING_ID', null)
        commit('SET_REDIRECTS', response.data)

        done(response.data)
      } catch (error) {
        fail(error)

        console.error('[createRedirect]', error)
      }
    },

    async searchRedirects ({ commit }) {
      commit('SET_REDIRECTIONS_LOADING', true)

      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`migration/${appID}/redirect`)

        commit('SET_REDIRECTS', response.data)
      } catch (error) {
        console.error('[searchRedirects]', error)
      } finally {
        commit('SET_REDIRECTIONS_LOADING', false)
      }
    },

    async deleteRedirect ({ commit, state }, redirectID) {
      commit('SET_REMOVING_ID', redirectID)

      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .delete(`migration/${appID}/redirect/${redirectID}`)

        commit('SET_REDIRECTS', response.data)

        if (state.editingID === redirectID) {
          commit('SET_EDITING_ID', null)
        }

        window.$toast({
          color: 'success',
          message: 'Redirecionamento removido com sucesso'
        })
      } catch (error) {
        window.$toast({
          color: 'error',
          message: 'Houve um erro ao remover o redirecionamento'
        })

        console.error('[deleteRedirect]', error)
      } finally {
        commit('SET_REMOVING_ID', null)
      }
    }
  }
}
