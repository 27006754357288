
import rootfy from '@/core/utils/rootfy'

export default {
  state: {
    codes: {},
    currentCode: null,
    // hooks: [],
    openedCodes: [],
    codeLoading: false,
    isVersioning: false,
    lastCreatedAsset: {},
    backupCode: [],
    isCodesLoading: true,
    backupCodeLoading: false,
    diffCodes: [],
    isDiffLoading: true,
    selectedLineCode: null,
    isSavingCode: false,
    isUnifyingSingleFile: false
  },

  getters: {
    getCode: ({ openedCodes, currentCode }) => openedCodes.find(code => code.id === currentCode) ?? null,
    getCodes: state => {
      return Object.keys(state.codes)
        .map(key => ({
          key,
          label: key.replace(/_/g, ' '),
          data: rootfy(state.codes[key], 'codes')
        }))
        .reduce((acc, cur, index, all) => {
          if (cur.key === 'pages') {
            const { key, label, data } = cur

            return [
              ...acc,
              {
                key,
                label,
                data: [
                  ...data,
                  ...all.find(({ key }) => key === 'custom_pages').data
                ]
              }
            ]
          }

          if (['custom_pages'].includes(cur.key)) return acc

          return [
            ...acc, cur
          ]
        }, [])
    },
    getDiffCodes: state => state.diffCodes,
    getCurrentCode: state => state.currentCode,
    getIsDiffLoading: state => state.isDiffLoading,
    getCodeLoading: state => state.codeLoading,
    // getHooks: state => state.hooks,
    getIsVersioning: state => state.isVersioning,
    getBackupCode: state => state.backupCode,
    getBackupCodeLoading: state => state.backupCodeLoading,
    getOpenedCodes: state => {
      const { currentCode, openedCodes } = state

      const isCurrent = (id) => ({ isCurrent: currentCode === id })

      return structuredClone(openedCodes).map(code => Object.assign(code, isCurrent(code.id)))
    },
    getIsCodesLoading: state => state.isCodesLoading,
    getIsSavingCode: state => state.isSavingCode,
    getIsUnifyingSingleFile: state => state.isUnifyingSingleFile
  },

  mutations: {
    SET_CODE: (state, value) => {
      state.code = value

      // state.openedCodes = state.openedCodes.map(code => {
      //   if (value.id === code.id) return value
      //
      //   return code
      // })
    },
    SET_CODES: (state, value) => (state.codes = value),
    // SET_HOOKS: (state, value) => (state.hooks = value),
    SET_CODE_LOADING: (state, value) => (state.codeLoading = value),
    SET_IS_VERSIONING: (state, value) => (state.isVersioning = value),
    SET_BACKUP_CODE: (state, value) => (state.backupCode = value),
    SET_CURRENT_CODE: (state, value) => (state.currentCode = value),
    SET_BACKUP_CODE_LOADING: (state, value) => (state.backupCodeLoading = value),
    SET_OPENED_CODES: (state, value) => (state.openedCodes = value),
    SET_IS_CODES_LOADING: (state, value) => (state.isCodesLoading = value),
    SET_DIFF_CODES: (state, value) => (state.diffCodes = value),
    SET_IS_DIFF_LOADING: (state, value) => (state.isDiffLoading = value),
    SET_SELECTED_LINE_CODE: (state, value) => (state.selectedLineCode = value),
    SET_IS_SAVING_CODE: (state, value) => (state.isSavingCode = value),
    SET_UNIFYING_SINGLE_FILE: (state, value) => (state.isUnifyingSingleFile = value)
  },

  actions: {
    async createCode ({ commit }, { data, done, fail }) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request()
          .post(`/apps/${id}/code/create`, data)

        commit('SET_CODES', response.data)
        done(response.data)
      } catch (error) {
        fail(error.response ? error.response.data : error.message)
      }
    },

    async searchCodes ({ commit }) {
      commit('SET_IS_CODES_LOADING', true)

      try {
        const id = window.$route.params.appID

        const response = await window
          .$request()
          .get(`apps/${id}/codes`)

        commit('SET_CODES', response.data)
      } catch (error) {
        console.error('[searchCodes]:', error)
      } finally {
        commit('SET_IS_CODES_LOADING', false)
      }
    },

    async searchCode ({ commit, state }, codeID) {
      if (state.codeLoading) return

      commit('SET_CODE_LOADING', true)

      const openedCodes = structuredClone(state.openedCodes)

      try {
        const ocurrence = openedCodes.some(({ id }) => id === codeID)

        if (ocurrence) {
          window.$toast({
            color: 'yellow',
            message: 'Arquivo já foi aberto no editor'
          })

          return commit('SET_CURRENT_CODE', codeID)
        }

        if (openedCodes.length > 4) {
          return window.$toast({
            color: 'alert',
            message: 'Máximo de arquivos abertos atingido'
          })
        }

        const response = await window
          .$request()
          .get(`apps/${window.$route.params.appID}/code/${codeID}`)

        const { id, content, previous_content: previousContent, name, type, extension } = response.data

        commit('SET_CURRENT_CODE', id)

        commit('SET_OPENED_CODES', openedCodes.concat({
          id,
          name,
          type,
          extension,
          hasUnsavedChanges: false,

          content: {
            data: {
              code: content ?? ''
            },

            model: {
              id: null, state: null
            }
          },

          previous_content: {
            data: {
              code: previousContent ?? ''
            },

            model: {
              id: null, state: null
            }
          }
        }))
      } catch (error) {
        console.error('[searchCode]:', error)
      } finally {
        commit('SET_CODE_LOADING', false)
      }
    },

    async updateCode ({ commit }, { codeID, codeModule, codeContent }) {
      commit('SET_IS_SAVING_CODE', true)

      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .put(`/apps/${appID}/code/${codeID}/update`, {
            [codeModule]: codeContent
          })

        window.$toast({
          color: 'success',
          message: 'Código atualizado com sucesso'
        })
      } catch (error) {
        console.error('[updateCode]', error)

        window.$toast({
          color: 'error',
          message: 'Não conseguimos salvar seu código'
        })
      } finally {
        commit('SET_IS_SAVING_CODE', false)
      }
    },

    patchOpenedCodes ({ commit, state }, { id, data }) {
      const openedCodes = structuredClone(state.openedCodes).map(code => {
        if (code.id !== id) return code

        if (Object.keys(data).includes('isCurrent')) {
          delete data.isCurrent
        }

        return Object.assign(code, data)
      })

      commit('SET_OPENED_CODES', openedCodes)
    },

    async deleteCode ({ commit, state, dispatch }, codeID) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request()
          .delete(`apps/${id}/code/${codeID}/remove`)

        commit('SET_CODES', response.data)

        dispatch('closeCode', +codeID)

        window.$toast({
          color: 'success',
          message: 'Código removido com sucesso'
        })
      } catch (error) {
        console.log('[deleteCode]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao excluir o código'
        })
      } finally {
        dispatch('global/getMenu', {}, {
          root: true
        })
      }
    },

    setIsVersioning ({ commit }, status) {
      commit('SET_IS_VERSIONING', status)
    },

    async setUnifyVersion ({ commit, state, getters, dispatch }, data) {
      commit('SET_CODE_LOADING', true)

      try {
        const code = getters.getCode
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .post(`/apps/${appID}/code/${code.id}/unify`, data)

        dispatch('patchOpenedCodes', {
          id: code.id,
          data: Object.assign(code, {
            hasUnsavedChanges: false,
            content: {
              data: {
                code: response.data.content
              },

              model: code.content.model
            },

            previous_content: {
              data: {
                code: response.data.previous_content
              },

              model: code.previous_content.model
            }
          })
        })
      } catch (error) {
        console.error('[setUnifyVersion]', error)
      } finally {
        commit('SET_CODE_LOADING', false)
      }
    },

    async searchBackups ({ commit, state }) {
      commit('SET_BACKUP_CODE_LOADING', true)

      try {
        const { app_id: appID, id } = state.code

        const response = await window
          .$request()
          .get(`apps/${appID}/code/${id}/backup`)

        commit('SET_BACKUP_CODE', response.data)
      } catch (error) {
        console.error('[searchBackups]:', error)
      } finally {
        commit('SET_BACKUP_CODE_LOADING', false)
      }
    },

    async createBackup ({ commit, state }, { data, done, fail }) {
      try {
        const { app_id: appID, id: codeID } = state.code

        const response = await window
          .$request()
          .post(`apps/${appID}/code/${codeID}/backup`, data)

        commit('SET_BACKUP_CODE', response.data)

        done(response.data)
      } catch (error) {
        fail(error)

        console.error('[createBackup]:', error)
      }
    },

    async deleteBackup ({ commit, state }, backupID) {
      try {
        const { app_id: appID, id: codeID } = state.code

        const response = await window
          .$request()
          .delete(`apps/${appID}/code/${codeID}/backup/${backupID}`)

        commit('SET_BACKUP_CODE', response.data)

        window.$toast({
          color: 'success',
          message: 'Versão removida com sucesso'
        })
      } catch (error) {
        console.error('[deleteBackup]:', error)
      }
    },

    async restoreBackup ({ commit, state, dispatch }, backupID) {
      try {
        const { app_id: appID, id: codeID } = state.code

        const response = await window
          .$request()
          .put(`apps/${appID}/code/${codeID}/backup/${backupID}/restore`)

        commit('SET_CODE', response.data)

        window.$toast({
          color: 'success',
          message: 'A versão foi restaurada com sucesso'
        })
      } catch (error) {
        window.$toast({
          color: 'error',
          message: 'Houve um erro ao restaurar a versão'
        })

        console.error('[deleteBackup]:', error)
      }
    },

    closeCode ({ commit, state }, codeID) {
      const openedCodes = structuredClone(state.openedCodes)

      const fileIndex = openedCodes.findIndex(code => code.id === codeID)

      const removedCode = openedCodes.splice(fileIndex, 1).at(0)

      if (state.currentCode === removedCode.id) {
        const newOpenedIndex = openedCodes.length > 0
          ? Math.min(openedCodes.length - 1, Math.max(fileIndex, 0))
          : null

        commit('SET_CURRENT_CODE', openedCodes[newOpenedIndex]?.id ?? null)
      }

      commit('SET_OPENED_CODES', openedCodes)
    },

    updateTemporaryCode ({ commit, state }, code) {
      // eslint-disable-next-line camelcase
      const { content, previous_content, id } = code

      const openedCodesCopy = [...state.openedCodes].map(item => {
        if (item.id === id) {
          return {
            ...item,
            ...(content && { content }),
            // eslint-disable-next-line camelcase
            ...(previous_content && { previous_content })
          }
        }

        return item
      })

      commit('SET_OPENED_CODES', openedCodesCopy)
    },

    async searchDifferentCodes ({ commit }) {
      commit('SET_IS_DIFF_LOADING', true)

      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .get(`apps/${appID}/code/diff`)

        commit('SET_DIFF_CODES', response.data)
      } catch (error) {
        window.$toast({
          color: 'error',
          message: 'Não foi possível listar as versões de código'
        })

        console.error('[searchDifferentCodes]:', error)
      } finally {
        commit('SET_IS_DIFF_LOADING', false)
      }
    },

    async unifyDifferentCodes ({ commit, state }, { data, done, fail }) {
      try {
        const { appID } = window.$route.params

        const response = await window
          .$request()
          .put(`/apps/${appID}/code-diff`, {
            ...data, codes: data.codes.map(Number)
          })

        commit('SET_DIFF_CODES', [
          ...state.diffCodes.map(code => {
            if (response.data.find(({ id }) => id === code.id)) {
              return {
                ...code,
                updated: true,
                selected: false
              }
            }

            return code
          })
        ])

        const updatedCodeIsOpened = response.data.find(({ id }) => id === state.code.id)

        if (updatedCodeIsOpened) {
          commit('SET_CODE', {
            ...state.code,
            ...updatedCodeIsOpened
          })
        }

        commit('SET_OPENED_CODES', [
          ...state.openedCodes.map(code => {
            if (code.id === updatedCodeIsOpened?.id) return code

            return response.data.find(({ id }) => id === code.id) ?? code
          })
        ])

        done(response.data)
      } catch (error) {
        console.error('[unifyDifferentCodes]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao atualizar as versões de código'
        })

        fail(error)
      }
    }
  }
}
