export default {
  namespaced: true,

  state: {
    permission: {},
    permissions: []
  },

  getters: {
    getPermission: state => state.permission,
    getPermissions: state => state.permissions
  },

  mutations: {
    SET_PERMISSION: (state, value) => (state.permission = value),
    SET_PERMISSIONS: (state, value) => (state.permissions = value)
  },

  actions: {
    async createPermission ({ commit }, { data, done, fail }) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request('connect')
          .put(`permissions/dash/${id}`, data)

        commit('SET_PERMISSIONS', response.data)
        done(response.data)
      } catch (error) {
        console.error('[createPermission]', error)

        const errMessage = error.response?.data?.email[0] ?? error.response

        const finalMessage = /não existe/gi.test(errMessage)
          ? 'Não encontramos nenhuma conta na Increazy com esse e-mail.'
          : errMessage

        fail(finalMessage)
      }
    },

    async removePermission ({ commit }, permissionId) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request('connect')
          .delete(`permissions/dash/${id}`, {
            data: {
              permission_id: permissionId
            }
          })

        commit('SET_PERMISSIONS', response.data)
      } catch (error) {
        console.log('[removePermission]', error)
      }
    },

    async searchPermissions ({ commit }) {
      try {
        const id = window.$route.params.appID

        const response = await window
          .$request('connect')
          .get(`permissions/dash/${id}`)

        commit('SET_PERMISSIONS', response.data)
      } catch (error) {
        console.log('[searchPermissions]', error)
      }
    },

    setPermission ({ commit }, data) {
      commit('SET_PERMISSION', data)
    }
  }
}
