export default function (obj) {
  return Object
    .entries(obj)
    .reduce((acc, current) => {
      if (Array.isArray(current[1])) {
        current[1].forEach((item) => {
          acc.append(current[0] + '[]', item)
        })

        return acc
      }

      acc.append(current[0], current[1])

      return acc
    }, new FormData())
}
