
import encapsulate from '@/core/utils/encapsulate'
import { root } from '../../../../.eslintrc'

export default {
  namespaced: true,

  state: {
    module: {},
    loading: true,
    filters: {},
    isInProduction: false,
    isAsideOpened: false,
    sideMenu: []
  },

  getters: {
    getLoader: (state) => state.loading,
    getFilters: (state) => state.filters,
    getIsInProduction: (state) => state.isInProduction,
    getIsAsideOpened: (state) => state.isAsideOpened,
    getSideMenuItems: (state) => {
      const sideMenu = structuredClone(state.sideMenu)

      const CMSItems = Array.isArray(sideMenu) && sideMenu.length
        ? sideMenu.find(({ module }) => module === 'pwa')
        : []

      const menu = CMSItems?.links?.reduce((group, item) => {
        if (item?.soon) return group

        if (item.name === 'CMS') {
          Object.assign(item, {
            links: encapsulate(item.links)
          })
        }

        return group.concat(item)
      }, [])

      return menu || []
    },
    getCMSFolderNames: (state, getters) => {
      const cmsItems = getters?.getSideMenuItems?.find(({ name }) => name === 'CMS')?.links ?? []

      return cmsItems.reduce((pages, { folder }) => {
        if (folder && !pages.includes(folder)) {
          return pages.concat(folder)
        }

        return pages
      }, [])
    }
  },

  mutations: {
    CHANGE_MODULE: (state, value) => (state.module = value),
    SET_LOADER: (state, value) => (state.loading = value),
    SET_SIDE_MENU: (state, value) => (state.sideMenu = value),
    SET_FILTERS: (state, { slot, value }) => {
      if (state.filters[slot] === undefined) {
        state.filters[slot] = {}
      }

      Object.keys(value).forEach((name) => {
        state.filters[slot][name] = value[name]
      })
    },
    SET_FILTER: (state, { slot, name, value, toggle }) => {
      if (state.filters[slot] === undefined) {
        state.filters[slot] = {}
      }

      if (toggle && state.filters[slot][name] === value) {
        delete state.filters[slot][name]
      } else {
        state.filters[slot][name] = value
      }
    },
    SET_ISINPRODUCTION: (state, value) => (state.isInProduction = value),
    SET_ISASIDEOPENED: (state, value) => (state.isAsideOpened = value)
  },

  actions: {
    startModule ({ dispatch }) {
      localStorage.setItem('language', process.env.VUE_APP_I18N_LOCALE)

      const environment = JSON.parse(localStorage.getItem('is-in-production'))

      if (environment) {
        dispatch('setIsInProduction', environment)
      } else {
        localStorage.setItem('is-in-production', false)
      }
    },

    async getMenu ({ commit }) {
      const response = await window
        .$request()
        .get(`/apps/${window.$route.params.appID}/menu`)

      commit('SET_SIDE_MENU', response.data)
    },

    setIsAsideOpened ({ commit, state, rootState, dispatch }, payload = null) {
      const openStatus = payload ?? !state.isAsideOpened

      commit('SET_ISASIDEOPENED', openStatus)

      if (openStatus && Object.keys(rootState.cms.element ?? {}).length && !window.$route.name.includes('ide')) {
        dispatch('cms/setElement', {}, { root: true })
      }
    },

    setIsInProduction ({ commit }, status) {
      commit('SET_ISINPRODUCTION', status)
      localStorage.setItem('is-in-production', status)
    },

    setFilters ({ commit }, { slot, value }) {
      commit('SET_FILTERS', { slot, value })
    },

    removeFilters ({ commit }, { slot, value }) {
      commit('REMOVE_FILTERS', { slot, value })
    },

    setFilter ({ commit }, { slot, name, value, toggle }) {
      commit('SET_FILTER', { slot, name, value, toggle })
    },

    setActiveModule (context, value) {
      context.commit('CHANGE_MODULE', value)
    },

    setGlobalLoader ({ commit }, value) {
      commit('SET_LOADER', value)
    }
  }
}
