
export default (router) => {
  router.beforeEach((to, from, next) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })

    if (to.matched.some((record) => record.meta.requireLogin)) {
      if (
        (!localStorage.getItem('token') ||
          localStorage.getItem('token') === 'null') &&
        !params.token
      ) {
        window.location.href = `${process.env.VUE_APP_INCREAZY_CONNECT}/products?product=checkout`
      }
    }

    next()
  })
}
