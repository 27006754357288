import store from './store'

/**
 * exporta o módulo com suas configurações, dentro de options colocamos
 * as variáveis de configurações dele, assim podemos detectar globalmento o módulo ativo
 * para manipular o layout global
 */
export default {
  name: 'global',
  options: {},
  store,
  routes: []
}
