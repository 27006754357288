
import { cmsModules } from './utils/cmsModules'

export default [
  {
    path: '/cms/:appID',
    name: 'cms-content',
    meta: { requireLogin: true, hasSidebar: true },
    redirect: {
      name: 'checklist-home'
    },
    component: () => import(/* webpackChunkName: "cms-content-main" */ './pages/content'),
    children: cmsModules.map(module => ({
      path: `${module}/:pageID`,
      name: `cms-content-${module}`,
      component: () => import(`./pages/content/components/content-${module}`)
    }))
  }
]
