
import { createI18n } from 'vue-i18n/index'

const language = localStorage.getItem('language')

export default createI18n({
  locale: language || 'pt',
  fallbackLocale: 'pt',
  enableInSFC: true
})
