
import {
  variables,
  modules as mailModules,
  templates as mailTemplates
} from '../pages/utils/mailModules'

import { mailContext } from '../pages/utils/mailContext'

const iterableMailBase = Object
  .entries(mailModules)
  .reduce((modules, [module, groups]) => {
    return modules.concat(groups.map(group => ({ list: [], group: group })))
  }, [])

function groupFactory ({ groupName, groupIndex, oldList, appendItem }) {
  if (groupIndex > -1) {
    return oldList.map(({ group, list }) => {
      if (group === groupName) {
        return {
          group, list: [...list, appendItem]
        }
      }

      return {
        group, list
      }
    })
  }

  return [
    ...oldList,
    {
      group: groupName,
      list: [
        appendItem
      ]
    }
  ]
}

function iterableMails (mails) {
  return mails.reduce((acc, cur) => {
    const { group: groupName } = cur

    return groupFactory({
      groupName,
      oldList: acc,
      appendItem: cur,
      groupIndex: acc.findIndex(({ group }) => group === groupName)
    })
  }, iterableMailBase)
}

export default {
  state: {
    mail: {},

    actionType: null,

    mails: [],

    variables,

    mailModules
  },

  getters: {
    getMail: state => state.mail,
    getMails: state => state.mails,
    getVariables: state => state.variables,
    getActionType: state => state.actionType,
    getIterableMails: state => iterableMails(state.mails),
    getHasMail: state => !!Object.keys(state.mail).length,
    getMailModuleNames: state => Object.keys(state.mailModules)
  },

  mutations: {
    SET_MAIL (state, payload) {
      state.mail = payload ?? {}

      const payloadKeys = Object.keys(payload)

      if (payloadKeys.length === 0) {
        state.actionType = ''

        return
      }

      if (payloadKeys.includes('id')) {
        state.actionType = 'edit'
      }
    },

    SET_MAILS (state, payload) {
      state.mails = payload
    },

    SET_ACTION_TYPE (state, payload) {
      if (
        typeof payload === 'string' &&
        (payload.length === 0 || ['create', 'edit'].indexOf(payload) > -1)
      ) {
        state.actionType = payload

        return
      }

      throw new Error('MAIL_ACTION_TIME can only get "create" or "edit" values.')
    }
  },

  actions: {
    clearMail ({ commit }) {
      commit('SET_MAIL', {})
      commit('SET_ACTION_TYPE', '')
    },

    setMail ({ commit, dispatch, state }, { actionType, payload }) {
      commit('SET_ACTION_TYPE', actionType)

      const isTransactionalMail = state.mailModules.transactional.includes(payload?.group)

      commit('SET_MAIL', {
        group: '',
        sender_name: '',
        sender_email: '',
        immediate: 0,
        status: 1,
        metadata: {},
        schedule: '00:00:00',
        webhook: '',
        webhook_status: 0,
        subject: '',
        ...payload,
        ...(isTransactionalMail && {
          immediate: 1,
          schedule: '00:00:00'
        }),
        ...(actionType === 'create' && {
          body: mailTemplates.find(({ group }) => group === payload?.group)?.content || ''
        })
      })

      if (actionType === 'edit' && !payload.body) {
        dispatch('searchMail', { id: payload.id })
      }
    },

    setSpecifiedMail ({ commit, state }, { id, key, value }) {
      const { mail: currentMail, mails } = state

      const newProperties = {
        [key]: value
      }

      commit('SET_MAILS', mails.map(mail => {
        if (mail.id === id) {
          if ((Object.keys(currentMail).includes('id'))) {
            commit('SET_MAIL', { ...currentMail, ...newProperties })
          }

          return Object.assign(mail, newProperties)
        }

        return mail
      }))
    },

    async searchMail ({ commit, state, rootState }, { id: mailID }) {
      try {
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const response = await window
          .$request('mail')
          .get(`/emails/ecommerce/${checkoutID}/${mailID}`)

        commit('SET_MAIL', response.data)
        commit('SET_MAILS', [
          ...state.mails.map(mail => {
            return {
              ...mail,
              ...(mail.id === mailID && {
                body: response.data.body
              })
            }
          })
        ])
      } catch (e) {
        console.log('[searchMail]', e)

        window.$toast({
          color: 'error',
          message: 'Erro ao buscar e-mail'
        })
      }
    },

    async searchMails ({ commit, rootState }) {
      try {
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const response = await window
          .$request('mail')
          .get(`/emails/ecommerce/${checkoutID}`)

        commit('SET_MAILS', response.data)
      } catch (error) {
        console.log('[searchMails]', error)

        window.$toast({
          color: 'error',
          message: 'Erro ao buscar e-mails'
        })
      }
    },

    async createMail ({ commit, state, rootState }, { data, done, fail }) {
      try {
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const METHOD = Object.keys(data).find(key => key === 'id')
          ? 'put'
          : 'post'

        const editionMode = Object.keys(state.mail).includes('id')
          ? `/${state.mail.id}`
          : ''

        const _data = {
          ...data,
          ...state.mail
        }

        const response = await window.$request('mail')[METHOD](
          `/emails/ecommerce/${checkoutID}${editionMode}`, _data
        )

        done(response.data)

        commit('SET_MAIL', {})
        commit('SET_MAILS', response.data)
      } catch (e) {
        fail(e)

        console.error('[createMail]', e)

        window.$toast({
          color: 'error',
          message: 'Falha na criação do e-mail'
        })
      }
    },

    async setMailStatus ({ commit, state, getters, rootState }, { id, endpoint }) {
      try {
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const response = await window
          .$request('mail')
          .put(`/emails/ecommerce/${checkoutID}/${id}/${endpoint}`)

        commit('SET_MAILS', response.data)

        if (getters.getActionType === 'edit') {
          commit('SET_MAIL', {
            ...state.mail,
            status: response.data.find(({ id }) => id === state.mail.id).status
          })
        }

        window.$toast({
          color: 'success',
          message: 'O status do e-mail foi atualizado com sucesso'
        })
      } catch (error) {
        window.$toast({
          color: 'error',
          message: 'Não foi possível atualizar o status do e-mail'
        })

        console.error('[setMailStatus]', error)
      }
    },

    async updateMailContent ({ commit, state, rootState }, { data, done, fail }) {
      try {
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const response = await window
          .$request('mail')
          .put(`/emails/ecommerce/${checkoutID}/${data.id}`, {
            ...state.mail,
            ...data
          })

        commit('SET_MAILS', response.data)

        done && done(response.data)
      } catch (e) {
        console.log('[updateMailContent]', e)

        fail && fail(e)

        window.$toast({
          color: 'error',
          message: ''
        })
      }
    },

    async sendMailPreview ({ state, rootState }, { data, done, fail }) {
      try {
        const { checkout_id: checkoutID } = rootState.apps?.app?.settings

        const {
          subject, body
        } = state.mail

        const _data = {
          ...data,
          subject,
          body,
          context: {
            customer: {
              name: rootState.auth?.user?.name ?? 'John Doe',
              email: rootState.auth?.user?.email ?? 'johndoe@awesomemail.com'
            },

            paymentType: data['payment-method'] ?? 'pix',

            ...mailContext
          }
        }

        const response = await window
          .$request('mail')
          .post(`/emails/ecommerce/${checkoutID}/test`, _data)

        done(response.data)
      } catch (e) {
        fail(e)

        console.error('[sendMailPreview]', e)
      }
    }
  }
}
