
import codeManager from './code-manager.js'
import fileManager from './file-manager.js'
import recycleBin from './recycle-bin'
import globalSearch from './global-search'

export default {
  namespaced: true,

  state: {
    ...codeManager.state,
    ...fileManager.state,
    ...recycleBin.state,
    ...globalSearch.state
  },

  getters: {
    ...codeManager.getters,
    ...fileManager.getters,
    ...recycleBin.getters,
    ...globalSearch.getters
  },

  mutations: {
    ...codeManager.mutations,
    ...fileManager.mutations,
    ...recycleBin.mutations,
    ...globalSearch.mutations
  },

  actions: {
    ...codeManager.actions,
    ...fileManager.actions,
    ...recycleBin.actions,
    ...globalSearch.actions,

    async startModule ({ commit }) {
      commit('SET_CODE', {})
      commit('SET_CODES', {})
      // commit('SET_HOOKS', [])
      commit('SET_FILES', [])
      commit('SET_LOAD_BIN', false)
      commit('SET_OPENED_CODES', [])
      // clear global search
      commit('SET_TERM', '')
      commit('SET_LOADING_SEARCH', false)
      commit('SET_ABORT_CONTROLLER', null)
      commit('SET_GLOBAL_SEARCH', {
        codes: [], files: []
      })
    }
  }
}
