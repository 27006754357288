import toFormData from '@/core/utils/toformdata'

export default {
  namespaced: true,

  state: {
    app: {},
    apps: [],
    cloningApp: {},
    isModalCreateAppVisible: false
  },

  getters: {
    getApp: state => state.app,
    getApps: state => state.apps,
    getCloningApp: state => {
      return Object.keys(state.cloningApp ?? {}).length
        ? state.cloningApp
        : false
    }
  },

  mutations: {
    SET_APP: (state, value) => (state.app = value),
    SET_APPS: (state, value) => (state.apps = value),
    SET_CLONING_APP: (state, value) => (state.cloningApp = value),
    SET_MODAL_CREATE_APP_VISIBILITY: (state, payload) => (state.isModalCreateAppVisible = payload)
  },

  actions: {
    setApp ({ commit }, data) {
      commit('SET_APP', data)
    },

    async createApp ({ commit }, { data, done, fail }) {
      try {
        const response = await window
          .$request()
          .post('/apps/create', data)

        commit('SET_APP', response.data)
        done(response.data)
      } catch (error) {
        fail(error.response ? error.response.data : error.message)
      }
    },

    async updateApp ({ commit }, { data, done, fail }) {
      try {
        const appID = window.$route.params.appID

        data = toFormData(data)

        const response = await window
          .$request()
          .post(`/apps/${appID}/update`, data)

        commit('SET_APP', response.data)
        done(response.data)
      } catch (error) {
        fail(error.response ? error.response.data : error.message)
      }
    },

    async searchApps ({ commit }, params) {
      try {
        if (window.$route.name === 'apps') {
          params.per_page = 6
        }

        const response = await window.$request().get('/apps', { params })
        commit('SET_APPS', response.data)
      } catch (error) {
        commit('SET_APPS', [])
      }
    },

    async searchApp ({ commit, dispatch }) {
      try {
        const appID = window.$route.params.appID ?? localStorage.getItem('app')
        if (!appID) return

        const response = await window
          .$request()
          .get(`/apps/${appID}`)

        commit('SET_APP', response.data)

        dispatch('global/getMenu', {}, {
          root: true
        })
      } catch (error) {
        commit('SET_APP', {})
      } finally {
        dispatch('settings-pwa/searchEcommerceDetails', null, {
          root: true
        })

        commit('global/SET_LOADER', false, { root: true })
      }
    },

    async toggleAppStatus ({ commit }, { appID, method }) {
      try {
        if (!(appID || method)) return

        commit('global/SET_LOADER', true, { root: true })
        commit('global/SET_FILTER', { slot: 'apps', name: 'status', value: undefined }, { root: true })

        const response = await window
          .$request()
          .put(`apps/${appID}/${method}`)

        commit('SET_APPS', response.data)

        window.$toast({
          color: 'success',
          message: `O app foi ${method === 'pause' ? 'desativado' : 'ativado'}`
        })
      } catch (error) {
        window.$toast({
          color: 'error',
          message: `Houve um erro ao ${method === 'pause' ? 'desativar' : 'ativar'} o app`
        })

        console.error('[toggleAppStatus]', error)
      } finally {
        commit('global/SET_LOADER', false, { root: true })
      }
    },

    async cloneApp ({ commit }, { data, done, fail }) {
      try {
        const { id, name, url } = data

        const response = await window
          .$request()
          .put(`/apps/${id}/clone`, { name, url })

        commit('SET_APP', response.data)
        done(response.data)
      } catch (error) {
        fail(error.response ? error.response.data : error.message)
      }
    }
  }
}
