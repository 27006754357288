export default {
  state: {
    syslogs: []
  },

  getters: {
    getSysLogs: state => state.syslogs
  },

  mutations: {
    SET_SYS_LOGS: (state, value) => (state.syslogs = value)
  },

  actions: {
    async searchLogs ({ commit, rootState }, { data, done, fail }) {
      try {
        const appID = window.$route.params.appID

        const response = await window
          .$request()
          .post(`migration/${appID}/log/search`, Object.assign(data, {
            query: data.query ?? '',
            results: parseInt(data.results),
            checkout_id: parseInt(data.checkout_id)
          }))

        commit('SET_SYS_LOGS', response.data)

        done(response)
      } catch (error) {
        console.error('[searchLogs]', error)

        fail(error)
      }
    }
  }
}
