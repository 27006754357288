export default (app) => {
  app.mixin({
    methods: {
      $followPageTransition (selector, lessScroll = 0, auxClass = '') {
        const container = document.querySelector(selector)

        if (window.innerWidth > 1024) {
          window.addEventListener('scroll', () => {
            this.isOnTop = window.scrollY <= 0

            if (container.classList.contains(auxClass)) {
              container.style.transform = `translateY(${window.scrollY}px)`
            } else {
              container.style.transform = `translateY(${(window.scrollY - lessScroll) >= 0 ? (window.scrollY - lessScroll) : 0}px)`
            }
          }, {
            passive: true
          })
        }
      }
    }
  })
}
