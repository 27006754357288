import libComponents from './lib-components'

export default {
  namespaced: true,

  state: {
    ...libComponents.state
  },

  getters: {
    ...libComponents.getters
  },

  mutations: {
    ...libComponents.mutations
  },

  actions: {
    ...libComponents.actions
  }
}
