
export default {
  state: {
    smtp: {},
    smtpVisible: false,
    hasSMTPFetched: false
  },

  getters: {
    getSMTP: (state) => state.smtp,
    hasSMTP: (state) => Object.keys(state.smtp).length > 0,
    getSMTPVisible: state => state.smtpVisible,
    cameFromServer: (state, getters) => getters.hasSMTP && state.smtp?.fromServer
  },

  mutations: {
    SET_SMTP: (state, value) => {
      const smtp = structuredClone(state.smtp)

      state.smtp = Array.isArray(value) && !value.length
        ? {}
        : Object.assign(smtp, value)

      state.smtpVisible = false
    },
    SET_SMTP_STATUS: (state, status) => Object.assign(state.smtp, { status }),
    SET_SMTP_VISIBLE: (state, value) => (state.smtpVisible = value ?? false)
  },

  actions: {
    async searchSMTP ({ commit, state }) {
      try {
        const appID = window.$route.params.appID

        const response = await window
          .$request('connect')
          .get(`emails/${appID}/smtp`)

        if (response.data?.host) {
          commit('SET_SMTP', Object.assign({ fromServer: true }, response.data))
        } else {
          commit('SET_SMTP', {})
        }
      } catch (error) {
        console.error('[searchSMTP]', error)
      }
    },

    async updateSMTP ({ commit }, { data, fail, done }) {
      try {
        const appID = window.$route.params.appID

        const response = await window
          .$request('connect')
          .post(`emails/${appID}/smtp`, Object.assign(data, {
            status: +data.status
          }))

        commit('SET_SMTP', Object.assign({ fromServer: true }, response.data))

        done(response)
      } catch (error) {
        console.log('[updateSMTP]', error)

        fail(error)
      }
    },

    async updateSMTPStatus ({ commit }, status) {
      commit('SET_SMTP_STATUS', status)

      try {
        const appID = window.$route.params.appID

        const endpoint = {
          0: 'pause',
          1: 'resume'
        }[status | 0]

        const response = await window
          .$request('connect')
          .put(`emails/${appID}/smtp/${endpoint}`)

        commit('SET_SMTP', Object.assign({ fromServer: true }, response.data))

        window.$toast({
          color: 'success',
          message: 'Status alterado com sucesso'
        })
      } catch (error) {
        console.error('[updateSMTPStatus]', error)

        window.$toast({
          color: 'error',
          message: 'Falha ao alterar o status'
        })
      }
    },

    async sendSMTPTest ({ commit, state }, { data, done, fail }) {
      try {
        const appID = window.$route.params.appID

        const _data = Object.assign(data, {
          status: 1,
          port: parseInt(data?.port)
        })

        const response = await window
          .$request('connect')
          .put(`emails/${appID}/smtp`, _data)

        done(response)
      } catch (error) {
        fail(error)

        console.error('[sendSMTPTest]', error)
      }
    }
  }
}
