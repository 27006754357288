export default [
  {
    path: '/checklist',
    name: 'checklist',
    meta: { hasSidebar: true },
    component: () => import(/* webpackChunkName: "checklist" */ './pages/index'),
    children: [
      {
        path: ':appID',
        name: 'checklist-home',
        component: () => import(/* webpackChunkName: "checklist-home" */ './pages/Home/index')
      }
    ]
  }
]
