
const triggersModules = [
  'inserted',
  'updated',
  'removed'
]

const triggerModels = [
  'sms',
  'email',
  'change',
  'webhook'
]

function getTriggerBase ({ model, actionType }) {
  if (!triggerModels.includes(model)) {
    throw new Error(`"${model}" is not a valid trigger model`)
  }

  if (!triggersModules.includes(actionType)) {
    throw new Error(`"${actionType}" is not a valid trigger action`)
  }

  const triggerBase = {
    type: actionType,
    conditions: [],
    ...(['email', 'sms', 'webhook'].includes(model) && {
      body: ''
    })
  }

  switch (model) {
    case 'email':
      return Object.assign(triggerBase, {
        subject: '',
        sender_name: '',
        sender_email: '',
        target_email: ''
      })
    case 'sms':
      return Object.assign(triggerBase, {
        target_phone: '',
        target_country: ''
      })
    case 'change':
      return Object.assign(triggerBase, {
        changes: []
      })
    case 'webhook':
      return Object.assign(triggerBase, {
        url: '',
        method: '',
        headers: []
      })
  }
}

function createTriggersModules (triggersList) {
  const modules = triggersModules.reduce((modules, moduleName) => ({
    ...modules, [moduleName]: []
  }), {})

  return triggersList.reduce((acc, trigger) => {
    const { type } = trigger

    return Object.assign(acc, { [type]: [...acc[type], trigger] })
  }, modules)
}

function parseTrigger ({ model, content }) {
  const trigger = {
    ...content,
    conditions: content.conditions.reduce((conditions, condition) => {
      if (String(condition.id).startsWith('temp-')) delete condition.id

      return conditions.concat(condition)
    }, []),
    ...(Array.isArray(content.headers) && {
      headers: content.headers.map(header => {
        if (String(header?.id).startsWith('temp-')) delete header.id

        return header
      })
    })
  }

  if (trigger?.action) delete trigger.action

  return trigger
}

function translateTriggerPayload (trigger) {
  const { action_type: actionType } = trigger

  if (trigger?.action?.id) delete trigger.action.id

  return {
    ...trigger,
    ...trigger?.action,
    conditions: trigger.conditions
      .map(({ column_id: columnID, condition, id, value }) => ({
        id,
        value,
        condition,
        attribute: columnID
      })),
    action: '',
    ...(actionType === 'change' && {
      changes: trigger?.action?.changes.reduce((acc, { column_id: columnID, ...rest }) => {
        return [
          ...acc, {
            column: columnID,
            ...rest
          }
        ]
      }, [])
    })
  }
}

export {
  parseTrigger,
  triggerModels,
  triggersModules,
  getTriggerBase,
  createTriggersModules,
  translateTriggerPayload
}
