export default {
  state: {
    meta: {},
    tagsAux: []
  },

  getters: {
    meta: (state) => state.meta
  },

  mutations: {
    SET_META: (state, data) => (state.meta = data),
    SET_TAGS: (state, data) => (state.tagsAux = data)
  },

  actions: {
    async searchMeta ({ commit }) {
      try {
        const { appID, pageID } = window.$route.params

        const response = await window
          .$request()
          .get(`/apps/${appID}/code/${pageID}/meta`)

        commit('SET_META', response.data)
      } catch (error) {
        console.error('[searchMeta]:', error)
      }
    },

    async deleteMeta ({ commit, state }, metaID) {
      try {
        const { appID, pageID } = window.$route.params

        const response = await window
          .$request()
          .delete(`/apps/${appID}/code/${pageID}/tag/${metaID}`)

        commit('SET_META', Object.assign(state.meta, { tags: response.data }))

        window.$toast({
          color: 'success',
          message: 'Meta tag removida com sucesso.'
        })
      } catch (error) {
        console.error('[deleteMeta]:', error)

        window.$toast({
          color: 'error',
          message: 'Houve um erro com a exclusão da meta tag'
        })
      }
    },

    async updateMeta ({ commit, dispatch, state }, { data, done, fail }) {
      try {
        const { appID, pageID } = window.$route.params

        dispatch('translateTags', data)

        const _body = {
          title: data.title,
          keywords: data.keywords,
          description: data.description,
          ...(data.path && { path: data.path }),
          ...(state.tagsAux.length && { tags: state.tagsAux })
        }

        const response = await window
          .$request()
          .put(`/apps/${appID}/code/${pageID}/meta`, _body)

        commit('SET_META', {
          ...state.meta,
          title: response.data.title,
          path: response.data.path,
          tags: [...response.data.tags]
        })

        done(response.data)
      } catch (error) {
        console.error('[updateMeta]:', error)
        fail(error)
      }
    },

    translateTags ({ commit }, data) {
      const tags = []
      const tagsAux = []
      const propsPerItem = 4

      Object
        .entries(data)
        .forEach((item) => {
          if (item[0].startsWith('tag')) {
            tagsAux.push(item)
          }
        })

      for (let i = 0, len = tagsAux.length / propsPerItem; i < len; i++) {
        tags.push({})
      }

      tagsAux.forEach((item, index) => {
        const i = Math.ceil((index + 1) / propsPerItem) - 1
        const key = item[0].split('.')[1]

        if (item[1] !== 'NULLABLE') {
          tags[i][key] = key === 'id' ? +item[1] : (item[1] ?? '')
        }
      })

      commit('SET_TAGS', tags)
    }
  }
}
