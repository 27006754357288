
const NotificationList = () => import(
  /* webpackChunkName: "notification-list-index" */
  './pages/components/notification-list/index.vue'
)

export default [
  {
    path: '/push-notification/:appID',
    name: 'push-notification',
    meta: { hasSidebar: true },
    component: () => import(
      /* webpackChunkName: "push-notification" */
      './pages/index.vue'
    ),
    children: [
      {
        path: 'scheduled',
        name: 'push-notification-module-scheduled',
        component: NotificationList,
        props: {
          title: 'scheduled',
          allowedModules: []
        }
      },

      {
        path: 'fired',
        name: 'push-notification-module-fired',
        component: NotificationList,
        props: {
          title: 'fired',
          allowedModules: []
        }
      },

      {
        path: 'triggers',
        name: 'push-notification-module-triggers',
        component: NotificationList,
        props: {
          title: 'triggers',
          allowedModules: []
        }
      }
    ]
  },

  {
    path: '/push-notification/:appID/create',
    name: 'create-push-notification',
    meta: { hasSidebar: true },
    component: () => import(
      /* webpackChunkName: "create-push-notification" */
      './pages/create-push-notification/index.vue'
    )
  }
]
