export default function (data) {
  let conditionIndex = null

  const conditionsAux = Object
    .entries(data)
    .reduce((acc, cur) => {
      if (!cur[0].includes('conditions')) return acc

      const [key, value] = cur

      const conditionKey = key.split('.')[1]

      conditionIndex = +key.replace(/\D+/g, '')

      if (acc[conditionIndex]) {
        acc.splice(conditionIndex, 1, {
          ...acc[conditionIndex],
          [conditionKey]: value
        })

        return acc
      }

      return [
        ...acc, {
          [conditionKey]: value
        }
      ]
    }, [])

  return conditionsAux
}
