
export default function rootfy (rootfyList, deepKey = 'files') {
  return rootfyList.reduce((acc, cur) => {
    if (cur.name === 'root') {
      return [
        ...cur[deepKey],
        ...acc
      ]
    }

    return [
      ...acc,
      cur
    ]
  }, [])
}
