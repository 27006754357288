import storeAdvanced from './advanced'
import storePermissions from './permissions'

export default {
  namespaced: true,

  state: {
    ...storeAdvanced.state,
    ...storePermissions.state
  },

  getters: {
    ...storeAdvanced.getters,
    ...storePermissions.getters
  },

  mutations: {
    ...storeAdvanced.mutations,
    ...storePermissions.mutations
  },

  actions: {
    ...storeAdvanced.actions,
    ...storePermissions.actions
  }
}
