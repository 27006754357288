
export const mailContext = {
  id: '0001',
  total: 209.99,
  discount: 24.9,
  subtotal: 234.89,
  // paymentType: '', submission performed dynamically by the form
  recover_path: '#recover_path#',
  delivery_path: '#delivery_path#',

  coupon: 'PRIMEIRA10',
  delivery_price: 29.90,
  delivery_name: 'SEDEX',
  date: new Date().toLocaleDateString('pt-BR'),
  status: 'aprovado',
  userID: '02615948',
  nf: '#Nota fiscal#',
  delivery: 'Delivery SEDEX',
  url: 'https://google.com',
  expiration: new Date((new Date()).getTime() + (86400000 * 3)).toLocaleDateString('pt-BR'),
  barcode: '#barcode#',
  qrcode: '#QRCODE#',

  // products
  products: [
    {
      id: '0001',
      name: 'Produto teste 1',
      quantity: 1,
      price: 229.99,
      sku: 'SKU0001',
      discount: 20.00,
      image: ''
    },

    {
      id: '0002',
      name: 'Produto teste 2',
      quantity: 2,
      price: 14.90,
      sku: 'SKU0002',
      discount: 4.90,
      image: ''
    }
  ]
}
