export default (app) => {
  app.mixin({
    methods: {
      /**
       * Aplica ou remove o estilo no momento de arrastar o arquivo para dentro do container
       * @param {Event} ev Evento
       * @param {String} zone container/div que irá receber o drop
       * @param {String} classStyle estilo aplicado ao arrastar o arquivo ao container/div
       * @param {boolean} status remover ou adicionar o estilo (true, false)
       */
      $dropStyle (ev, zone, classStyle, status) {
        ev.preventDefault()
        const dropzone = ev.target.closest(zone)

        if (dropzone) {
          if (status) dropzone.classList.add(classStyle)
          else dropzone.classList.remove(classStyle)
        }
      },

      /**
       * Seta o conteúdo no input e retorna a url do arquivo para aplicar no front
       * @param {Event} e Evento
       * @param {String} inp selector do input
       * @returns
       */
      $dropFile (e, inp) {
        e.preventDefault()
        const inputElement = document.querySelector(inp)

        if (e.dataTransfer.files) {
          inputElement.files = e.dataTransfer.files

          return inputElement.files
        }

        return {}
      }
    }
  })
}
