import { createApp } from 'vue'
import App from './core/App.vue'
import router from './core/router'
import store from './core/store'
import modules from './core/modules'
import request from '@/core/request'
import i18n from './core/i18n'
import mitt from 'mitt'
import { useUtils } from '@/core/utils'
import middlewares from '@/core/middlewares'

window.$request = request

modules(router, store)
middlewares(router, store)

const app = createApp(App)

const emitter = mitt()
app.config.globalProperties.emitter = emitter
app.config.unwrapInjectedRef = true

app.mixin({
  watch: {
    '$i18n.locale': {
      deep: true,
      handler (value) {
        localStorage.setItem('language', value)
        window.location.reload()
      }
    }
  }
})

useUtils(app)

app.use(i18n)
app.use(store)
app.use(router)
app.mount('#app')
