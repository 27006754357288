
import database from './database'
import tableDetails from './table-details'
import triggers from './triggers'

export default {
  namespaced: true,

  state: {
    ...database.state,
    ...tableDetails.state,
    ...triggers.state
  },

  getters: {
    ...database.getters,
    ...tableDetails.getters,
    ...triggers.getters
  },

  mutations: {
    ...database.mutations,
    ...tableDetails.mutations,
    ...triggers.mutations
  },

  actions: {
    ...database.actions,
    ...tableDetails.actions,
    ...triggers.actions
  }
}
