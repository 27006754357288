import axios from 'axios'

function getBaseURL (type) {
  if (!type) return process.env.VUE_APP_BACKEND_API

  return {
    pay: process.env.VUE_APP_BACKEND_PAYMENT_API,
    connect: process.env.VUE_APP_CONNECT_API,
    mail: process.env.VUE_APP_CONNECT_API,
    indexer: process.env.VUE_APP_INDEXER_API,
    ecommerce: process.env.VUE_APP_ECOMMERCE_API
  }[type]
}

/**
 * Cria uma instância do axios com as configurações padrões para
 * a API do backend, esse método é armazenado na variável
 * window.$request para ser acessível no Vuex
 */
export default (type = null) => {
  const { appID } = (window.$route || {}).params || {}
  const customHeaders = appID ? { App: appID } : {}

  const params = new URLSearchParams(window.location.search)

  const headers = {
    Language: localStorage.getItem('language'),
    Token: params.get('token') || localStorage.getItem('token'),
    ...customHeaders
  }

  return axios.create({
    timeout: 60000,
    headers: headers,
    baseURL: getBaseURL(type)
  })
}
