
import { settingsModules } from './utils/modules'

export default [
  {
    path: '/settings/:appID',
    name: 'global-settings',
    meta: { hasSidebar: true },
    redirect: {
      name: `settings-${settingsModules.at(0)}`
    },
    component: () => import(/* webpackChunkName: "global-settings" */ './pages/index'),
    children: settingsModules.map(module => ({
      path: module,
      name: `settings-${module}`,
      meta: {
        hasSidebar: true
      },
      component: () => import(`./pages/${module}`)
    }))
  }
]
