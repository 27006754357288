import toFormData from '@/core/utils/toformdata'
import translateConditions from '@/core/utils/translate-conditions'
import { root } from '../../../../.eslintrc'

export default {
  state: {
    elements: [],
    loadingElements: true,
    element: {},
    visibilities: {},
    defaultPageNames: ['Global', 'category', 'search', 'error', 'product', 'home', 'layout']
  },

  getters: {
    getElement: state => state.element,
    getElements: state => state.elements,
    getLoadingElements: state => state.loadingElements,
    getDefaultPageNames: state => state.defaultPageNames,
    getPageList: (state, getters, rootState) => {
      const sideMenuItems = structuredClone(rootState?.global?.sideMenu ?? [])

      if (!Array.isArray(sideMenuItems) && !sideMenuItems.length) return []

      const PWAModule = sideMenuItems.find(({ module }) => module === 'pwa')?.links ?? []

      return PWAModule.find(({ name }) => name === 'CMS')?.links ?? []
    }
  },

  mutations: {
    SET_ELEMENT: (state, value) => (state.element = value),
    SET_ELEMENTS: (state, value) => (state.elements = value),
    SET_VISIBILITIES: (state, value) => (state.visibilities = value),
    SET_LOADING_ELEMENTS: (state, value) => (state.loadingElements = value)
  },

  actions: {
    async searchElements ({ commit }, params) {
      commit('SET_LOADING_ELEMENTS', true)

      try {
        const appID = window.$route.params.appID || localStorage.getItem('app')
        const pageID = window.$route.params.pageID

        if (!(appID || pageID)) return

        commit('SET_ELEMENTS', [])

        const response = await window
          .$request()
          .get(`/cms/${appID}/search/${pageID}`, { params })

        commit('SET_ELEMENTS', response.data)
      } catch (error) {
        commit('SET_ELEMENTS', [])

        console.error('[searchElements]', error)
      } finally {
        commit('SET_LOADING_ELEMENTS', false)
      }
    },

    setElement ({ commit, dispatch }, element) {
      commit('SET_ELEMENT', element)

      if (!Object.keys(element ?? {}).length) return

      dispatch('global/setIsAsideOpened', false, { root: true })
    },

    async updateElement ({ commit }, { data, done, fail }) {
      try {
        const appID = window.$route.params.appID || localStorage.getItem('app')
        const pageID = window.$route.params.pageID

        const response = await window.$request().post(`/cms/${appID}/search/${pageID}`, data)

        commit('SET_ELEMENTS', response.data)
        done(response.data)
      } catch (error) {
        fail(error)
      }
    },

    async updateElementImage ({ commit, dispatch, state }, { data, done, fail }) {
      try {
        // eslint-disable-next-line
        if (data.has_desktop == 'false' && !data.desktop?.name) {
          return fail('A imagem desktop é obrigatória.')
        // eslint-disable-next-line
        } else if (data.has_desktop == 'true' && !data.desktop?.name) {
          delete data.desktop
        }

        const appID = window.$route.params.appID || localStorage.getItem('app')

        data.name = data.name ?? ''
        data.description = data.description ?? ''
        data.url = data.url ?? ''
        data.new_tab = data.new_tab ?? 0

        if (data.switch) delete data.switch

        if ([0, undefined].includes(data['visibility_condition[status]'])) {
          delete data['visibility_condition[attribute]']
          delete data['visibility_condition[condition]']
          delete data['visibility_condition[value]']
          delete data['visibility_condition[status]']
        }

        if ([0, undefined].includes(data['visibility_period[status]'])) {
          delete data['visibility_period[status]']
          delete data['visibility_period[start_date]']
          delete data['visibility_period[end_date]']
        } else {
          dispatch('translateVisibilities', { data, type: 'json' })

          data['visibility_period[friday]'] = state.visibilities?.visibilityPeriod?.friday || 0
          data['visibility_period[monday]'] = state.visibilities?.visibilityPeriod?.monday || 0
          data['visibility_period[saturday]'] = state.visibilities?.visibilityPeriod?.saturday || 0
          data['visibility_period[sunday]'] = state.visibilities?.visibilityPeriod?.sunday || 0
          data['visibility_period[thursday]'] = state.visibilities?.visibilityPeriod?.thursday || 0
          data['visibility_period[tuesday]'] = state.visibilities?.visibilityPeriod?.tuesday || 0
          data['visibility_period[wednesday]'] = state.visibilities?.visibilityPeriod?.wednesday || 0
        }

        if ([0, undefined].includes(data['visibility_by_entities[status]'])) {
          delete data['visibility_by_entities[status]']
          delete data['visibility_by_entities[condition]']
          delete data['visibility_by_entities[value]']
        }

        data = {
          ...data
        }

        if (!data.content_id) delete data.content_id
        if (!data.mobile?.name) delete data.mobile

        data = toFormData(data)

        const response = await window.$request().post(`/cms/${appID}/image`, data)

        commit('SET_ELEMENTS', response.data)
        done(response.data)
      } catch (error) {
        fail(error)
      }
    },

    async updateElementText ({ commit, dispatch, state }, { data, done, fail }) {
      try {
        const appID = window.$route.params.appID || localStorage.getItem('app')

        if (!data.content_id) delete data.content_id

        dispatch('translateVisibilities', { data, type: 'json' })

        const { visibilities } = state

        const body_ = {
          page_id: data.page_id,
          content_id: data.content_id,
          placeholder: data.placeholder,
          content: data.content == null ? '' : data.content,
          name: data.name == null ? '' : data.name,
          visibility_condition: {
            ...visibilities.visibilityCondition
          },
          visibility_period: {
            ...visibilities.visibilityPeriod
          },
          visibility_by_entities: {
            ...visibilities.visibilityByEntities
          }
        }

        // eslint-disable-next-line
        if (body_.visibility_condition.status == 0) delete body_.visibility_condition

        const response = await window.$request().post(`/cms/${appID}/${data.type_text}`, body_)

        commit('SET_ELEMENTS', response.data)
        done(response.data)
      } catch (error) {
        fail(error)
      }
    },

    async updateElementEntity ({ commit, dispatch, state }, { data, done, fail }) {
      try {
        const appID = window.$route.params.appID || localStorage.getItem('app')
        dispatch('translateVisibilities', { data, type: 'json' })

        const visibilities = state.visibilities

        const conditions = translateConditions(data)

        const body_ = {
          page_id: data.page_id,
          content_id: data.content_id,
          placeholder: data.placeholder,
          conditions,
          visibility_condition: {
            ...visibilities.visibilityCondition
          },
          visibility_period: {
            ...visibilities.visibilityPeriod
          }
        }

        if (body_.visibility_condition.status === 0) delete body_.visibility_condition
        if (body_.visibility_period.status === 0) delete body_.visibility_period
        if (!body_.content_id) delete body_.content_id
        if (visibilities?.visibilityByEntities?.condition) {
          body_.visibility_by_entities = {
            condition: visibilities.visibilityByEntities.condition,
            value: visibilities.visibilityByEntities.value
          }
        }

        const response = await window.$request().post(`/cms/${appID}/entity`, body_)

        commit('SET_ELEMENTS', response.data)
        done(response.data)
      } catch (error) {
        fail(error)
      }
    },

    // eslint-disable-next-line camelcase
    async removeContent ({ commit, state }, { placeholder, content_id }) {
      try {
        const appID = window.$route.params.appID || localStorage.getItem('app')
        const pageID = window.$route.params.pageID

        const body_ = {
          // eslint-disable-next-line camelcase
          content_id,
          placeholder,
          page_id: pageID
        }

        const response = await window
          .$request()
          .delete(`/cms/${appID}/remove`, {
            data: body_
          })

        commit('SET_ELEMENTS', response.data)

        // eslint-disable-next-line camelcase
        if (state.element.content_id === content_id) {
          commit('SET_ELEMENT', {})
        }

        window.$toast({
          color: 'success',
          message: 'O elemento foi removido com sucesso'
        })
      } catch (error) {
        window.$toast({
          color: 'error',
          message: 'Falha ao remover elemento'
        })

        // console.error('[removeContent]', error)
      }
    },

    /**
     * @param {*} data valores do body da request
     * @param {*} type formato do retorno
     * Método que traduz e facilida a leitura dos visibilities
     * sendo setado no state @param state.visibilities
     * quando o formato for "json", ele retorna ambos os visibilities
     * quando nao for, ele vai retornar apenas a tradução dos
     * dias da semana para setar os valores no formato (0, 1), pois não será necessário
     * retornar ambos os visibilities, pois o form data aceita o formato enviado pelos campos,
     * não havendo a necessidade de traduzir o retorno para json.
     */
    translateVisibilities ({ commit }, { data, type }) {
      const weekDays = 'sunday|monday|tuesday|wednesday|thursday|friday|saturday'.split('|')

      if (type === 'json') {
        commit('SET_VISIBILITIES', {
          visibilityCondition: {
            attribute: data['visibility_condition[attribute]'],
            condition: data['visibility_condition[condition]'],
            value: data['visibility_condition[value]'],
            status: data['visibility_condition[status]'] ?? 0
          },
          visibilityPeriod: {
            end_date: data['visibility_period[end_date]'],
            start_date: data['visibility_period[start_date]'],
            status: data['visibility_period[status]'] ?? 0,
            ...weekDays.reduce((acc, cur) => ({
              ...acc,
              [cur]: (data[`visibility_period[${cur}]`] === 'on') >>> 0
            }), {})
          },
          visibilityByEntities: {
            condition: data['visibility_by_entities[condition]'] ?? '',
            value: data['visibility_by_entities[value]'] ?? '',
            status: +data['visibility_by_entities[status]'] ?? 0
          }
        })

        return
      }

      commit('SET_VISIBILITIES', weekDays.reduce((visibilities, weekDay) => ({
        ...visibilities,
        [`visibility_period[${weekDay}]`]: (data[`visibility_period[${weekDay}]`] === 'on') >>> 0
      }), {}))
    },

    async toggleElementStatus ({ commit }, data) {
      try {
        const appID = window.$route.params.appID || localStorage.getItem('app')

        // eslint-disable-next-line camelcase
        const { content_id, currentStatus } = data

        const routeAction = currentStatus ? 'pause' : 'resume'

        const response = await window.$request().put(`/cms/${appID}/${routeAction}`, {
          // eslint-disable-next-line camelcase
          page_id: +window.$route.params.pageID, content_id
        })

        commit('SET_ELEMENTS', response.data)

        window.$toast({
          color: 'success',
          message: `O elemento foi ${currentStatus ? 'pausado' : 'reativado'} com sucesso`
        })
      } catch (error) {
        console.error('[cms/toggleElementStatus]', error)
      }
    },

    async updateElementsOrder ({ commit }, orderData) {
      try {
        const { appID, pageID } = window.$route.params

        const response = await window
          .$request()
          .put(`/cms/${appID}/order`, {
            orders: orderData,
            page_id: parseInt(pageID)
          })

        commit('SET_ELEMENTS', response.data)

        window.$toast({
          color: 'success',
          message: 'Nova organização aplicada com sucesso'
        })
      } catch (error) {
        console.error('[updateElementsOrder]', error)
      }
    }
  }
}
