export default {
  namespaced: true,

  state: {
    user: null,
    token: null
  },

  getters: {
    getUser: (state) => state.user,
    getToken: (state) => state.token
  },

  mutations: {
    SET_USER: (state, value) => (state.user = value),
    SET_TOKEN: (state, value) => {
      state.token = value

      if (value === '') {
        localStorage.removeItem('token')

        return
      }

      localStorage.setItem('token', value)
    }
  },
  actions: {
    /**
     * Ao iniciar o módulo temos que mostrar o loader global enquanto
     * autenticamos o token armazenado, caso o token for inválido ele
     * é apagado.
     */
    async startModule ({ commit, dispatch }) {
      try {
        let token = ''

        const params = new URLSearchParams(window.location.search)

        if (params.get('token')) {
          token = params.get('token')
        } else if (
          localStorage.getItem('token') &&
          localStorage.getItem('token') !== 'null'
        ) {
          token = localStorage.getItem('token')
        }

        localStorage.setItem('token', token)

        if (token) {
          // commit('global/SET_LOADER', true, { root: true })
          const response = await window.$request('connect').get('/auth')
          const { user, token } = response.data

          commit('SET_USER', user)
          commit('SET_TOKEN', token)
          dispatch('verifyUserPermissions', user)
        }
      } catch (error) {
        console.error('[startModule]', error)
        commit('SET_TOKEN', '')
      } finally {
        // commit('global/SET_LOADER', false, { root: true })
      }
    },

    logout ({ commit }) {
      commit('SET_USER', null)
      commit('SET_TOKEN', null)

      window.location.href = `${process.env.VUE_APP_INCREAZY_CONNECT}/products?product=dash`
    },

    verifyUserPermissions ({ commit }, user) {
      if (window.$route.name === 'apps-list') return

      let permissions = user?.permissions || []

      if (permissions.length > 0) {
        permissions = permissions.filter((p) => p.product === 'dash')
        permissions = permissions.map((p) => p.target)

        if (!permissions.includes(window.$route.params.appID)) {
          window.$toast({
            color: 'error',
            message: 'Você não tem permissão nesse aplicativo.'
          })

          setTimeout(() => {
            window.location.href = '/'
          }, 3500)
        }
      } else {
        window.$toast({
          color: 'error',
          message: 'Você não tem permissão nesse aplicativo.'
        })

        setTimeout(() => {
          window.location.href = '/'
        }, 3500)
      }
    }
  }
}
