import modules from '@/config/modules'

/**
 * Percorre todas as rotas do módulo, adicionando as opções dele
 * como metadata da rota, por fim adiciona a rota ao roteador do app
 * @param {vue-router} appRouter instância do roteador
 * @param {Object} _module objeto correspondente ao módulo
 */
const registerRoutesWithMeta = (appRouter, _module) => {
  const { routes, name, options } = _module
  const moduleMeta = { name, ...options }
  routes.forEach((route) => {
    route.meta = { module: moduleMeta, ...route.meta }
    route.props = true
    appRouter.addRoute(route)
  })
}

/**
 * Cria um middleware no roteador para, antes de cada rota,
 * enviar ao Vuex o módulo, produto e tema ativo no momento
 * @param {vue-router} appRouter instância do roteador
 * @param {vuex} appStore instância do vuex
 */
const registerRouterModuleMiddleware = (appRouter, appStore) => {
  appRouter.beforeEach(async (to, from, next) => {
    window.$route = to
    const toModule = to.meta.module || {}
    appStore.dispatch('global/setActiveModule', toModule)

    // const theme = localStorage.getItem('theme') || 'light'
    // const module = toModule.product
    // document.body.setAttribute(
    //   'class',
    //   `inc-theme-${theme} inc-product-${module}`
    // )

    const hasApp = Object.keys(appStore.getters['apps/getApp'] || {}).length > 0

    if (to.params.appID) {
      if (hasApp) {
        return next()
      }

      await appStore.dispatch('apps/searchApp')
    } else {
      appStore.dispatch('apps/setApp', {})
    }

    next()
  })
}

/**
 * Registra toda a store do módulo, além disso, se ela possuir o
 * activem 'startModule' ele é chamado para iniciar o módulo
 * @param {vuex} appStore instância do vuex
 * @param {Object} _module objeto correspondente ao módulo importado
 */
const registerStoreModuleAndConstruct = (appStore, _module) => {
  const { name, store } = _module

  appStore.registerModule(name, store)
  if (store.actions.startModule) {
    appStore.dispatch(`${name}/startModule`)
  }
}

/**
 * Percorre todos os módulos definidos em config para fazer a importação
 * de sua store e rotas. Por fim, cria a middleware de identificação de módulos
 */
export default (appRouter, appStore) => {
  modules.forEach((_module) => {
    registerStoreModuleAndConstruct(appStore, _module)
    registerRoutesWithMeta(appRouter, _module)
  })

  registerRouterModuleMiddleware(appRouter, appStore)
}
