import routes from './routes'
import store from './store'

/**
 * exporta o módulo com suas configurações, dentro de options colocamos
 * as variáveis de configurações dele, assim podemos detectar globalmento o módulo ativo
 * para manipular o layout global
 */
export default {
  name: 'data-base',
  options: {
    sidebar: true,
    darkTheme: true
  },
  store,
  routes
}
