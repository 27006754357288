
import { modules as searchOptionModules } from './pages/utils/searchOptionModules'
import { modules as mailModules, mailModuleProps, mailModuleNames } from './pages/utils/mailModules'

const MailList = () => import(/* webpackChunkName: "settings-pwa-mails" */ './pages/email/components/mail-list/index')

const searchOptionsViews = searchOptionModules
  .reduce((moduleObj, module) => ({
    ...moduleObj,
    [module]: () => import(`./pages/search-options/views/${module}/index`)
  }), {})

export default [
  {
    path: '/settings-pwa/:appID/mails',
    meta: { hasSidebar: true },
    component: () => import(/* webpackChunkName: "settings-pwa-mails" */ './pages/email/index'),
    redirect: {
      name: `settings-pwa-mails-${mailModuleNames.at(0)}`
    },
    children: mailModuleNames
      .map(moduleName => ({
        path: moduleName,
        name: `settings-pwa-mails-${moduleName}`,
        component: MailList,
        props: mailModuleProps(moduleName)
      }))
      .concat({
        path: 'smtp',
        name: 'settings-pwa-mails-smtp',
        component: () => import(
          /* webpackChunkName: "settings-pwa-mails-smtp" */ './pages/email/components/smtp/index'
        )
      })
  },

  {
    path: '/settings-pwa/:appID/mail/:mailID/edit',
    name: 'settings-pwa-mails-edit',
    meta: { hasSidebar: true },
    component: () => import(/* webpackChunkName: "settings-pwa-mail-edit" */ './pages/edit-mail-body')
  },

  {
    path: '/settings-pwa/:appID/search-options',
    meta: { hasSidebar: true },
    component: () => import(/* webpackChunkName: "settings-pwa-search-options" */ './pages/search-options'),
    children: searchOptionModules
      .map(moduleName => ({
        path: moduleName,
        name: `settings-pwa-${moduleName}`,
        component: searchOptionsViews[moduleName]
      }))
  },

  {
    path: '/settings-pwa/:appID/xmls',
    meta: { hasSidebar: true },
    children: [
      {
        path: '',
        name: 'settings-pwa-xmls',
        component: () => import(/* webpackChunkName: "settings-pwa-xmls" */ './pages/xmls')
      },
      {
        path: ':xmlID',
        name: 'settings-pwa-xmls-edit',
        component: () => import(/* webpackChunkName: "settings-pwa-xmls-edit" */ './pages/edit-xml-body')
      }
    ]
  },

  {
    path: '/settings-pwa/:appID/clusters',
    name: 'settings-pwa-cluster',
    meta: {
      hasSidebar: true
    },
    component: () => import(
      /* webpackChunkName: "settings-pwa-clusters" */ './pages/clusters/index'
    )
  },

  {
    path: '/settings-pwa/:appID',
    name: 'settings-pwa',
    meta: {
      hasSidebar: true
    },
    component: () => import(
      /* webpackChunkName: "settings-pwa" */ './pages'
    ),
    children: [
      {
        path: 'syslog',
        name: 'settings-pwa-syslog',
        component: () => import(
          /* webpackChunkName: "settings-pwa-syslog" */ './pages/syslog'
        )
      },

      {
        path: 'logs',
        name: 'settings-pwa-logs',
        component: () => import(
          /* webpackChunkName: "settings-pwa-logs" */ './pages/logs'
        )
      },

      {
        path: 'url-redirect',
        name: 'settings-pwa-redirect',
        component: () => import(
          /* webpackChunkName: "settings-pwa-redirect" */ './pages/url-redirect'
        )
      },

      {
        path: 'elastic-catalog',
        name: 'settings-pwa-elastic-catalog',
        component: () => import(
          /* webpackChunkName: "settings-pwa-elastic-catalog" */ './pages/elastic-catalog'
        )
      }
    ]
  }
]
