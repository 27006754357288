import followPageTransition from './follow-page-transition'
import store from './store'
import filters from './filters'
import allUpdate from './all-update'
import dropImage from './drop-image'
import hasSlot from './has-slot'
import profileMenuOptions from './profile-menu-options'
/**
 * Registra todos o utils no app Vue
 */
export const useUtils = (app) => {
  followPageTransition(app)
  store(app)
  filters(app)
  allUpdate(app)
  dropImage(app)
  hasSlot(app)
  profileMenuOptions(app)
}
